import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@duik/it';
import Icon from '@duik/icon';
import { selectJobs } from '../../../../shared/selectors/jobs/jobs-selector';
import { selectModal } from '../../../../shared/selectors/modal/modal-selector';
import JobsAction from '../../../../store/jobs/jobs-action';
import ModalAction from '../../../../store/modal/modal-action';
import cls from './jp-close-job-confirm.module.scss';

const mapStateToProps = (state) => ({
  jobs: selectJobs(state),
  modal: selectModal(state)
});

const JPCloseJobConfirm = ({ onClose, jobs, modal, dispatch }) => {
  const { type, jobId } = modal;
  const job = jobs.find((j) => j.id === jobId);

  return (
    <div className={cls['jp-close-job-confirm']}>
      <div className={cls['header']}>
        <h2 className={cls['header-title']}>Confirm</h2>
        <Button
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
          className={cls['header-icon']}
          square
        >
          <Icon>close</Icon>
        </Button>
      </div>
      <div className={cls['content']}>
        <div className={cls['content-title']}>{`Are you sure to close job "${job.name}"?`}</div>
        <div className={cls['content-buttons']}>
          <Button
            className={cls['ok']}
            onClick={(e) => {
              e.preventDefault();
              if (jobId) {
                dispatch(JobsAction.requestDeleteJobPost(jobId));
                dispatch(ModalAction.updateModalState({ isOpen: false, isSecondOpen: false }));
              }
            }}
          >
            Ok
          </Button>
          <Button
            className={cls['cancel']}
            onClick={(e) => {
              e.preventDefault();
              dispatch(ModalAction.updateModalState({ isSecondOpen: false }));
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(JPCloseJobConfirm);
