import React from 'react';
import { connect } from 'react-redux';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { FormGroupContainer, FormGroup, TextField, Button, Select } from '@duik/it';
import Icon from '@duik/icon';
import { selectReferences } from '../../../../../shared/selectors/reference/reference-selector';
import ReferencesAction from '../../../../../store/references/references-action';
import { getStateOption, states } from '../../../../../shared/sample-data';
import cls from './references-details.module.scss';
import StatesSelect from '../../../../../components/states-select/states-select';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  relationship: Yup.string().required('This field is required'),
  email: Yup.string().required('This field is required').email('Invalid Email'),
  companyName: Yup.string().required('This field is required'),
  city: Yup.string().required('This field is required'),
  state: Yup.object({
    value: Yup.string().required('This field is required')
  }),
  phoneNumber: Yup.string().required('This field is required')
});

const mapStateToProps = (state) => ({
  references: selectReferences(state)
});

const ReferencesDetails = ({ referenceId, references, dispatch }) => {
  const submitForm = (values) => {
    if (referenceId === 'new-reference') {
      dispatch(
        ReferencesAction.requestCreateReference({
          name: values.name,
          relationship: values.relationship,
          email: values.email,
          companyName: values.companyName,
          city: values.city,
          state: values.state.value,
          phoneNumber: values.phoneNumber
        })
      );
    } else {
      dispatch(
        ReferencesAction.requestUpdateReference({
          id: referenceId,
          name: values.name,
          relationship: values.relationship,
          email: values.email,
          companyName: values.companyName,
          city: values.city,
          state: values.state.value,
          phoneNumber: values.phoneNumber
        })
      );
    }
  };

  const reference = references.find(({ id }) => id === referenceId);

  const initialValues = {
    name: reference ? reference.name : '',
    relationship: reference ? reference.relationship : '',
    email: reference ? reference.email : '',
    companyName: reference ? reference.companyName : '',
    city: reference ? reference.city : '',
    state: getStateOption(reference ? reference.state : states[0]),
    phoneNumber: reference ? reference.phoneNumber : ''
  };

  return (
    <div className={cls['reference-details']}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitForm}
      >
        {({ values, errors, touched, handleSubmit, handleChange, handleBlur }) => (
          <form onSubmit={handleSubmit}>
            <FormGroupContainer className={cls['section1']}>
              <FormGroup>
                <TextField
                  label="FULL NAME"
                  placeholder="Enter first and last name"
                  name="name"
                  errorMessage={errors.name && touched.name && errors.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
              </FormGroup>
              <FormGroup>
                <TextField
                  label="RELATIONSHIP"
                  placeholder="Enter type of relationship"
                  name="relationship"
                  errorMessage={errors.relationship && touched.relationship && errors.relationship}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.relationship}
                />
              </FormGroup>
              <FormGroup>
                <TextField
                  label="E-MAIL ADDRESS"
                  placeholder="Enter reference's e-mail address"
                  name="email"
                  errorMessage={errors.email && touched.email && errors.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
              </FormGroup>
              <FormGroup>
                <TextField
                  label="COMPANY NAME"
                  placeholder="Enter reference's company name"
                  name="companyName"
                  errorMessage={errors.companyName && touched.companyName && errors.companyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.companyName}
                />
              </FormGroup>
              <div className={cls['location']}>
                <div className={cls['label']}>LOCATION</div>
                <div className={cls['inputs']}>
                  <FormGroup>
                    <TextField
                      placeholder="City"
                      name="city"
                      errorMessage={errors.city && touched.city && errors.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city}
                    />
                  </FormGroup>
                  <div style={{ minWidth: '120px', flexGrow: 1 }}>
                    <StatesSelect
                      showLabel={false}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                </div>
              </div>
              <FormGroup>
                <TextField
                  label="PHONE NUMBER"
                  placeholder="Enter reference's phone number"
                  name="phoneNumber"
                  errorMessage={errors.phoneNumber && touched.phoneNumber && errors.phoneNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phoneNumber}
                />
              </FormGroup>
              <div className={cls['buttons']}>
                <Button type="submit" className={cls['update']}>
                  <Icon mr>{referenceId === 'new-reference' ? 'plus_rounded' : 'cloud_up'}</Icon>
                  {referenceId === 'new-reference' ? 'Create' : 'Update'}
                </Button>
                {referenceId && referenceId !== 'new-reference' && (
                  <Button
                    className={cls['remove']}
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(ReferencesAction.requestDeleteReference(referenceId));
                    }}
                  >
                    <Icon mr>close</Icon>Remove
                  </Button>
                )}
              </div>
            </FormGroupContainer>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default connect(mapStateToProps)(ReferencesDetails);
