import ActionUtility from '../../utilities/action-utility';
import EducationsEffect from './educations-effect';

export default class EducationsAction {
  static REQUEST_EDUCATIONS = 'EducationsAction.REQUEST_EDUCATIONS';
  static REQUEST_EDUCATIONS_FINISHED = 'EducationsAction.REQUEST_EDUCATIONS_FINISHED';
  static requestEducations() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        EducationsAction.REQUEST_EDUCATIONS,
        EducationsEffect.requestEducations
      );
    };
  }

  static REQUEST_CREATE_EDUCATION = 'EducationsAction.REQUEST_CREATE_EDUCATION';
  static REQUEST_CREATE_EDUCATION_FINISHED = 'EducationsAction.REQUEST_CREATE_EDUCATION_FINISHED';
  static requestCreateEducation(data) {
    return async (dispatch, action) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        EducationsAction.REQUEST_CREATE_EDUCATION,
        EducationsEffect.requestCreateEducation,
        data
      );
    };
  }

  static REQUEST_UPDATE_EDUCATION = 'EducationsAction.REQUEST_UPDATE_EDUCATION';
  static REQUEST_UPDATE_EDUCATION_FINISHED = 'EducationsAction.REQUEST_UPDATE_EDUCATION_FINISHED';
  static requestUpdateEducation(data) {
    return async (dispatch, action) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        EducationsAction.REQUEST_UPDATE_EDUCATION,
        EducationsEffect.requestUpdateEducation,
        data
      );
    };
  }

  static REQUEST_DELETE_EDUCATION = 'EducationsAction.REQUEST_DELETE_EDUCATION';
  static REQUEST_DELETE_EDUCATION_FINISHED = 'EducationsAction.REQUEST_DELETE_EDUCATION_FINISHED';
  static requestDeleteEducation(id) {
    return async (dispatch, action) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        EducationsAction.REQUEST_DELETE_EDUCATION,
        EducationsEffect.requestDeleteEducation,
        id
      );
    };
  }
}
