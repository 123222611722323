import React from 'react';
import { Checkbox } from '@duik/it';
import cls from './form-checkbox.module.scss';

const FormCheckbox = ({ data, formValue, setFieldValue, fieldName, type, disabled }) => {
  return (
    <Checkbox
      key={data.name}
      className={cls['check-label']}
      color="blue"
      label={data.name}
      onClick={(e) => {
        if (type === 'radio') {
          setFieldValue(fieldName, data.id);
        } else {
          setFieldValue(
            fieldName,
            formValue.some((v) => v === data.id)
              ? formValue.filter((v) => v !== data.id)
              : [...formValue, data.id]
          );
        }
      }}
      onChange={() => {}}
      checked={type === 'radio' ? formValue === data.id : formValue.some((v) => v === data.id)}
      disabled={disabled}
    />
  );
};

FormCheckbox.defaultProps = {
  type: 'checkbox'
};

export default FormCheckbox;
