import React from 'react';
import cls from './reference.module.scss';

const Reference = ({ data }) => {
  return (
    <div className={cls['reference']}>
      <div className={cls['reference-column']}>
        <div className={cls['item']}>
          NAME: <span>{data ? data.name : ''}</span>
        </div>
        <div className={cls['item']}>
          RELATIONSHIP: <span>{data ? data.relationship : ''}</span>
        </div>
      </div>
      <div className={cls['reference-column']}>
        <div className={cls['item']}>
          COMPANY NAME: <span>{data ? data.companyName : ''}</span>
        </div>
        <div className={cls['item']}>
          LOCATION: <span>{data ? data.city + ', ' + data.state : ''}</span>
        </div>
      </div>
      <div className={cls['reference-column']}>
        <div className={cls['item']}>
          E-MAIL ADDRESS: <span>{data ? data.email : ''}</span>
        </div>
        <div className={cls['item']}>
          PHONE NUMBER: <span>{data ? data.phoneNumber : ''}</span>
        </div>
      </div>
    </div>
  );
};

export default Reference;
