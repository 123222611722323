import { toast } from 'react-toastify';
import BaseReducer from '../../utilities/base-reducer';
import AuthAction from './auth-action';
import TokenStorage from '../../shared/token-storage/token-storage';
import { UserTypes } from '../../shared/constants/user-types';

export default class AuthReducer extends BaseReducer {
  initialState = {
    registerType: UserTypes.seeker,
    registerName: '',
    registerEmail: '',
    role: localStorage.getItem('role') || null,
    isAuthenticated: TokenStorage.getToken() ? true : false,
    user: null,
    config: null,
    signupSucceed: false,
    resetPasswordSucceed: false,
    changePasswordSucceed: false
  };

  [AuthAction.REQUEST_SIGNIN_FINISHED](state, action) {
    const { role, token } = action.payload;
    TokenStorage.storeToken(token);
    localStorage.setItem('role', role);

    return { ...state, role, isAuthenticated: true };
  }

  [AuthAction.REQUEST_SEEKER_SIGNUP_FINISHED](state, action) {
    const { role, token, signupSucceed } = action.payload;
    if (role && token) {
      TokenStorage.storeToken(token);
      localStorage.setItem('role', role);
    }

    return token ? { ...state, role, isAuthenticated: true } : { ...state, signupSucceed };
  }

  [AuthAction.REQUEST_PROVIDER_SIGNUP_FINISHED](state, action) {
    const { role, token, signupSucceed } = action.payload;
    if (role && token) {
      TokenStorage.storeToken(token);
      localStorage.setItem('role', role);
    }

    return token ? { ...state, role, isAuthenticated: true } : { ...state, signupSucceed };
  }

  [AuthAction.REQUEST_ACTIVATE_USER_FINISHED](state, action) {
    const { role, token } = action.payload;
    if (role && token) {
      TokenStorage.storeToken(token);
      localStorage.setItem('role', role);
    }
    return { ...state, role, isAuthenticated: true };
  }

  [AuthAction.REQUEST_RESET_PASSWORD_FINISHED](state, action) {
    return { ...state, resetPasswordSucceed: true };
  }

  [AuthAction.REQUEST_CHANGE_PASSWORD_FINISHED](state, action) {
    return { ...state, changePasswordSucceed: true };
  }

  [AuthAction.UPDATE_SIGNUP_STATUS](state, action) {
    const { signupSucceed, signupEmail } = action.payload;

    return { ...state, signupSucceed, signupEmail };
  }

  [AuthAction.REQUEST_USER_FINISHED](state, action) {
    return { ...state, user: action.payload };
  }

  [AuthAction.REQUEST_CONFIG_FINISHED](state, action) {
    return { ...state, config: action.payload };
  }

  [AuthAction.REQUEST_UPDATE_SEEKER_FINISHED](state, action) {
    if (state.user && state.user.openedProfile) {
      toast.success('User updated successfully');
    }
    return { ...state, user: action.payload };
  }

  [AuthAction.REQUEST_UPDATE_PROVIDER_FINISHED](state, action) {
    if (state.user && state.user.openedProfile) {
      toast.success('User updated successfully');
    }
    return { ...state, user: action.payload };
  }

  [AuthAction.REQUEST_UPDATE_AVATAR_FINISHED](state, action) {
    return { ...state, user: { ...state.user, profilePic: action.payload.profilePic } };
  }

  [AuthAction.LOGOUT_USER](state, action) {
    TokenStorage.clear();
    localStorage.removeItem('role');

    return {
      ...state,
      role: null,
      isAuthenticated: false
    };
  }

  [AuthAction.UPDATE_REGISTER_DATA](state, action) {
    const { registerType, registerName, registerEmail } = action.payload;

    return { ...state, registerType, registerName, registerEmail };
  }

  [AuthAction.SIGNIN_FROM_OAUTH](state, action) {
    const { role, token } = action.payload;
    TokenStorage.storeToken(token);
    localStorage.setItem('role', role);

    return { ...state, isAuthenticated: true, role };
  }
}
