import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@duik/it';
import Avatar from 'react-avatar';
import Icon from '@duik/icon';
import Divider from '../../../../components/divider/divider';
import { selectCandidates } from '../../../../shared/selectors/candidate/candidate-selector';
import { selectFilters } from '../../../../shared/selectors/filters/filters-selector';
import ModalAction from '../../../../store/modal/modal-action';
import JobsAction from '../../../../store/jobs/jobs-action';
import { ModalTypes } from '../../../../store/modal/models/modal-types';
import * as images from '../../../../assets/images/index';
import { selectJobs } from '../../../../shared/selectors/jobs/jobs-selector';
import { selectUser } from '../../../../shared/selectors/auth/auth-selector';
import { jobCandidateStatus } from '../../../../shared/constants/job-candidate-status';
import cls from './jp-candidate-search-modal.module.scss';

const mapStateToProps = (state) => ({
  user: selectUser(state),
  jobs: selectJobs(state),
  candidates: selectCandidates(state),
  filters: selectFilters(state)
});

const JPCandidateSearchModal = ({
  onClose,
  user,
  jobs,
  candidateId,
  candidates,
  filters,
  dispatch
}) => {
  const candidate = candidates.find((c) => c.id === candidateId);
  const managements =
    candidate && filters.jobRoles
      ? candidate.jobRoles.reduce((total, current, index) => {
          const management = filters.jobRoles.find((role) => role.id === current);
          return total.concat(
            management ? (index === 0 ? management.name : `, ${management.name}`) : ''
          );
        }, '')
      : [];
  const education =
    filters.educationLevels && candidate
      ? filters.educationLevels.find((e) => e.id === candidate.educationLevel)
      : null;
  const jobTypes =
    candidate && filters.jobTypes
      ? candidate.jobTypes.map((jt) => {
          const jobType = filters.jobTypes.find((t) => t.id === jt);
          return jobType.name;
        })
      : [];

  const segments =
    candidate && filters.segments
      ? candidate.segments.map((jt) => {
          const segment = filters.segments.find((t) => t.id === jt);
          return segment.name;
        })
      : [];

  const licenses =
    candidate && filters.licenses
      ? candidate.licenses.map((jt) => {
          const license = filters.licenses.find((t) => t.id === jt);
          return license.name;
        })
      : [];

  const showInfo =
    jobs && candidate && user
      ? jobs
          .filter((job) => job.jobOwner === user.id)
          .some((job) =>
            job.appliedUsers.some(
              (au) =>
                au.candidateId.toString() === candidate.id &&
                (au.status === jobCandidateStatus.applied || au.status === jobCandidateStatus.hired)
            )
          )
      : false;

  const isNew =
    jobs.filter((job) =>
      job.appliedUsers.some((au) => {
        return au.candidateId.toString() === candidateId;
      })
    ).length === 0;

  const canHire =
    jobs && candidate && user
      ? jobs
          .filter((job) => job.jobOwner === user.id)
          .some((job) => {
            return job.appliedUsers.some((au) => {
              return (
                au.candidateId.toString() === candidate.id &&
                au.status === jobCandidateStatus.applied
              );
            });
          })
      : false;

  return (
    <div className={cls['jp-candidate-search-modal']}>
      {!!candidate ? (
        <>
          <div className={cls['header']}>
            <h2 className={cls['header-title']}>
              {candidate.firstName + (showInfo ? ' ' + candidate.lastName : '')}
            </h2>
            {showInfo && (
              <div className={cls['header-info']}>
                <div>E-MAIL ADDRESS: {candidate ? candidate.email : ''}</div>
                <div>PHONE NUMBER: {candidate ? candidate.phoneNumber : ''}</div>
              </div>
            )}
            <Button
              onClick={(e) => {
                e.preventDefault();
                onClose();
              }}
              className={cls['header-icon']}
              square
            >
              <Icon>close</Icon>
            </Button>
          </div>
          <Divider />
          <div className={cls['content']}>
            <div className={cls['title-section']}>
              <div className={cls['titles']}>
                <div className={cls['title']}>
                  {(showInfo ? candidate.address + ' ' : '') +
                    candidate.city +
                    ' ' +
                    candidate.state}
                </div>
                {isNew && <div className={cls['title']}>New Candidate</div>}
                <div className={cls['title']}>Commute: {candidate.maxCommute} miles</div>
                <div className={cls['title']}>{managements}</div>
                <div className={cls['title']}>Minimum Salary Req: ${candidate.minSalaryReq}</div>
              </div>
              <div className={cls['icon']}>
                <Avatar
                  src={
                    showInfo && candidate && candidate.profilePic && candidate.profilePic.location
                      ? candidate.profilePic.location
                      : images.profile1
                  }
                  size={160}
                  round
                />
              </div>
            </div>
            {candidate.headline && (
              <div className={cls['desc-section']}>
                <div className={cls['desc-title']}>DESCRIPTION/OBJECTIVE</div>
                <div className={cls['description']}>{candidate.headline}</div>
              </div>
            )}
            <div className={cls['years-section']}>
              <div className={cls['years']}>Experience: {candidate.experience} Years</div>
              <div className={cls['years']}>Education: {education ? education.name : ''}</div>
            </div>
            <div className={cls['props-section']}>
              <div className={cls['column']}>
                <div className={cls['column-title']}>Job Type</div>
                {jobTypes.map((jt, i) => (
                  <div key={i} className={cls['column-text']}>
                    {jt}
                  </div>
                ))}
              </div>
              <div className={cls['column']}>
                <div className={cls['column-title']}>Insurance Segment</div>
                {segments.map((s, i) => (
                  <div key={i} className={cls['column-text']}>
                    {s}
                  </div>
                ))}
              </div>
              <div className={cls['column']}>
                <div className={cls['column-title']}>Required Licensing</div>
                {licenses.map((ls, i) => (
                  <div key={i} className={cls['column-text']}>
                    {ls}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={cls['footer']}>
            <Button
              className={cls['resume']}
              onClick={(e) => {
                e.preventDefault();
                dispatch(
                  ModalAction.updateModalState({
                    isOpen: true,
                    type: ModalTypes.JPRequestResume,
                    candidateId
                  })
                );
              }}
            >
              <Icon mr>profile_card</Icon>Request Resume
            </Button>

            <Button
              className={cls['interview']}
              onClick={(e) => {
                e.preventDefault();
                dispatch(
                  ModalAction.updateModalState({
                    isOpen: true,
                    type: ModalTypes.JPRequestInterview,
                    candidateId
                  })
                );
              }}
            >
              <Icon mr>wallet</Icon>Request Interview
            </Button>
            {canHire && (
              <Button
                className={cls['hire']}
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(
                    ModalAction.updateModalState({
                      isOpen: true,
                      type: ModalTypes.JPRequestHire,
                      candidateId
                    })
                  );
                }}
              >
                <Icon mr>wallet</Icon>Hire Candidate
              </Button>
            )}
            {showInfo && (
              <Button
                className={cls['view']}
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(
                    ModalAction.updateModalState({
                      isOpen: true,
                      type: ModalTypes.fullResume,
                      candidateId
                    })
                  );
                }}
              >
                <Icon mr>trending_up</Icon>View Full Resume
              </Button>
            )}
          </div>
        </>
      ) : (
        <div className={cls['header']}>
          <h2 className={cls['header-title']}>Invalid Candidate Id</h2>
          <Button
            onClick={(e) => {
              e.preventDefault();
              onClose();
            }}
            className={cls['header-icon']}
            square
          >
            <Icon>close</Icon>
          </Button>
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(JPCandidateSearchModal);
