import { StyleSheet } from '@react-pdf/renderer';

export const regularFont = 'Roboto-Regular';
export const boldFont = 'Roboto-Bold';

export const grey1 = '#080808';
export const grey2 = '#a9a9a9';
export const grey3 = '#d3d3d3';

export const green1 = '#08405c';
export const green2 = '#5e8396';
export const green3 = '#08405c';

const flexRowSpaceBetween = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
};

const flexColSpaceBetween = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
};

const flexRowCenter = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center'
};

export const mainStyles = StyleSheet.create({
  page: {
    padding: '10 20'
  },
  wrapper: {
    width: '100%',
    letterSpacing: 0.06,
    fontFamily: regularFont
  },
  header: {
    ...flexRowSpaceBetween,
    alignItems: 'center',
    marginRight: 8,
    marginLeft: 8,
    marginBottom: '6'
  },
  headerTitle: {
    fontFamily: boldFont,
    fontSize: '16'
  },
  headerInfo: {
    margin: 0,
    paddingTop: 0.4
  },
  headerInfoText: {
    fontSize: '10'
  },
  divider: {
    width: '100%',
    height: '1',
    backgroundColor: green1,
    margin: 0
  },
  titleSection: {
    ...flexRowSpaceBetween,
    margin: '8'
  },
  titles: {
    ...flexColSpaceBetween,
    width: '40%'
  },
  title: {
    fontSize: '14',
    color: grey1,
    margin: '2 0'
  },
  descSection: {
    width: '50%'
  },
  descTitle: {
    color: grey2,
    fontSize: 11
  },
  description: {
    fontSize: 10,
    margin: '6 0'
  },
  yearsSection: {
    width: '100%',
    ...flexRowCenter,
    marginTop: 12,
    marginBottom: 12,
    fontSize: 12,
    fontFamily: boldFont
  },
  experience: {
    marginLeft: 48
  },
  propsSection: {
    width: '90%',
    ...flexRowSpaceBetween,
    marginLeft: '5%',
    marginRight: '5%',
    marginTop: '6',
    textAlign: 'center'
  },
  propsColumn: {
    width: '30%'
  },
  propsColumnTitle: {
    fontSize: 12,
    marginBottom: 4,
    fontFamily: boldFont
  },
  propsColumnText: {
    fontSize: 11,
    margin: 2
  },
  detailsSection: {
    margin: '8'
  },
  detailsSectionRow: {
    margin: '8 0'
  },
  detailsSectionTitle: {
    fontFamily: boldFont,
    fontSize: 11
  }
});

export const workHistoryStyles = StyleSheet.create({
  content: {
    ...flexRowSpaceBetween,
    margin: '6 0'
  },
  column1: { width: '32%' },
  column1Text: { fontSize: 10, margin: '2 0' },
  column2: { width: '42%' },
  column2Title: { fontSize: 11, color: grey2 },
  column2Text: { fontSize: 10, margin: '2 0' },
  column3: { width: '22%' },
  column3Title: { fontSize: 11, color: grey2 },
  column3Text: { fontSize: 10, margin: '2 0' }
});
export const educationStyles = StyleSheet.create({
  content: {
    ...flexRowSpaceBetween,
    margin: '6 0'
  },
  column1: { width: '32%' },
  column1Text: { fontSize: 10, margin: '2 0' },
  column2: { width: '42%' },
  column2Title: { fontSize: 11, color: grey2 },
  column2Text: { fontSize: 10, margin: '2 0' },
  column3: { width: '22%' },
  column3Title: { fontSize: 11, color: grey2 },
  column3Text: { fontSize: 10, margin: '2 0' }
});
export const referenceStyles = StyleSheet.create({
  reference: {
    ...flexRowSpaceBetween,
    fontSize: 10,
    margin: '6 0'
  },
  column: {
    width: '32%'
  },
  item: {
    margin: '2 0'
  }
});
