import React from 'react';
import { Dropdown } from '@duik/it';
import cls from './dropdown-menu.module.scss';

const Select = ({ maxHeight, placeholder, options, activeOption, onOptionClick, ...rest }) => {
  return (
    <Dropdown
      buttonText={activeOption.label || placeholder}
      style={{
        width: '100%',
        maxHeight
      }}
      {...rest}
    >
      {({ handleClose }) => (
        <div className={cls['menu']}>
          {options.map((option, i) => (
            <div
              className={cls['item']}
              key={i}
              onClick={() => {
                onOptionClick(option);
                handleClose();
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </Dropdown>
  );
};

export default Select;
