import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Button } from '@duik/it';
import Icon from '@duik/icon';
import Divider from '../../../../components/divider/divider';
import ReferencesDetails from './references-details/references-details';
import { selectUser } from '../../../../shared/selectors/auth/auth-selector';
import { selectRequesting } from '../../../../shared/selectors/requesting/requesting-selector';
import AuthAction from '../../../../store/auth/auth-action';
import ReferencesAction from '../../../../store/references/references-action';
import ModalAction from '../../../../store/modal/modal-action';
import { ModalTypes } from '../../../../store/modal/models/modal-types';
import cls from './js-references-wizard-modal.module.scss';

const mapStateToProps = (state) => ({
  requesting: selectRequesting(state, [
    ReferencesAction.REQUEST_CREATE_REFERENCE,
    ReferencesAction.REQUEST_UPDATE_REFERENCE,
    ReferencesAction.REQUEST_DELETE_REFERENCE
  ]),
  user: selectUser(state)
});

const JPReferencesWizardModal = ({ onClose, user, requesting, dispatch }) => {
  const [references, setReferences] = useState([]);

  useMemo(() => {
    if (user) {
      setReferences(user.references);
    }
  }, [user]);

  // request user to refresh the page
  useMemo(() => {
    if (!requesting) {
      dispatch(AuthAction.requestUser());
    }
  }, [requesting]);

  return (
    <div className={cls['js-references-wizard-modal']}>
      <div className={cls['header']}>
        <h2 className={cls['header-title']}>Add References</h2>
        <div className={cls['header-right']}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              onClose();
            }}
            className={cls['close-icon']}
            square
          >
            <Icon>close</Icon>
          </Button>
        </div>
      </div>
      <Divider />
      <div className={cls['content']}>
        {references.map((reference, index) => (
          <ReferencesDetails referenceId={reference} key={index} />
        ))}
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Button
            className={cls['add']}
            onClick={(e) => {
              e.preventDefault();
              setReferences([...references, 'new-reference']);
            }}
          >
            <Icon mr>plus_rounded</Icon>Add Reference
          </Button>
        </div>
      </div>
      <Divider />
      <div className={cls['footer']}>
        <Button
          className={cls['prev']}
          onClick={(e) => {
            e.preventDefault();
            dispatch(
              ModalAction.updateModalState({ isOpen: true, type: ModalTypes.jsEducationWizard })
            );
          }}
        >
          <Icon mr>arrow_left</Icon>Back
        </Button>
        <Button
          className={cls['next']}
          onClick={(e) => {
            e.preventDefault();
            dispatch(ModalAction.updateModalState({ isOpen: true, type: ModalTypes.fullResume }));
          }}
        >
          <Icon mr>view_list</Icon>View Full Resume
        </Button>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(JPReferencesWizardModal);
