import React from 'react';
import { Page, Document, Font, View, Text } from '@react-pdf/renderer';
import WorkHistoryTemplate from './work-history-template';
import EducationTemplate from './education-template';
import ReferenceTemplate from './reference-template';
import { mainStyles } from './styles';

Font.register({
  family: 'Roboto-Regular',
  src: 'https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu4mxP.ttf'
});

Font.register({
  family: 'Roboto-Bold',
  src: 'https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlvAw.ttf'
});

const ResumePDFTemplate = ({ data, showInfo }) => {
  const {
    userName,
    email,
    phoneNumber,
    address,
    maxCommute,
    minSalaryReq,
    managements,
    description,
    education,
    experience,
    jobTypes,
    segments,
    licenses,
    userCurrentWorkHistory,
    userPreviousWorkHistories,
    userEducations,
    userReferences
  } = data;

  return (
    <Document>
      <Page size="LETTER" style={mainStyles.page}>
        <View style={mainStyles.wrapper}>
          <View style={mainStyles.header}>
            <Text style={mainStyles.headerTitle}>{userName}</Text>
            {showInfo && (
              <View style={mainStyles.headerInfo}>
                <Text style={mainStyles.headerInfoText}>E-MAIL ADDRESS: {email}</Text>
                <Text style={mainStyles.headerInfoText}>PHONE NUMBER: {phoneNumber}</Text>
              </View>
            )}
          </View>
          <View style={mainStyles.divider} />
          <View style={mainStyles.content}>
            <View style={mainStyles.titleSection}>
              <View style={mainStyles.titles}>
                <Text style={mainStyles.title}>{address}</Text>
                <Text style={mainStyles.title}>Maximum Commute: {maxCommute} Miles</Text>
                <Text style={mainStyles.title}>Minium Salary: ${minSalaryReq}</Text>
                <Text style={mainStyles.title}>{managements}</Text>
              </View>
              <View style={mainStyles.descSection}>
                <Text style={mainStyles.descTitle}>DESCRIPTION / OBJECTIVE</Text>
                <Text style={mainStyles.description}>{description}</Text>
              </View>
            </View>
            <View style={mainStyles.yearsSection}>
              <Text style={mainStyles.education}>Education: {education}</Text>
              <Text style={mainStyles.experience}>Experience: {experience} years</Text>
            </View>
            <View style={mainStyles.propsSection}>
              <View style={mainStyles.propsColumn}>
                <Text style={mainStyles.propsColumnTitle}>Job Type</Text>
                {jobTypes.map((jt, i) => (
                  <Text key={i} style={mainStyles.propsColumnText}>
                    {jt}
                  </Text>
                ))}
              </View>
              <View style={mainStyles.propsColumn}>
                <Text style={mainStyles.propsColumnTitle}>Insurance Segment</Text>
                {segments.map((sg, i) => (
                  <Text key={i} style={mainStyles.propsColumnText}>
                    {sg}
                  </Text>
                ))}
              </View>
              <View style={mainStyles.propsColumn}>
                <Text style={mainStyles.propsColumnTitle}>Current Licensing</Text>
                {licenses.map((ls, i) => (
                  <Text key={i} style={mainStyles.propsColumnText}>
                    {ls}
                  </Text>
                ))}
              </View>
            </View>
            <View style={mainStyles.detailsSection}>
              <View style={mainStyles.detailsSectionRow}>
                <Text style={mainStyles.detailsSectionTitle}>Work History</Text>
                {userCurrentWorkHistory && (
                  <WorkHistoryTemplate current data={userCurrentWorkHistory} />
                )}
                {userPreviousWorkHistories.map((history, index) => (
                  <WorkHistoryTemplate data={history} key={index} />
                ))}
              </View>
              <View style={mainStyles.detailsSectionRow}>
                <Text style={mainStyles.detailsSectionTitle}>Education</Text>
                {userEducations.map((education, index) => (
                  <EducationTemplate data={education} key={index} />
                ))}
              </View>
              <View style={mainStyles.detailsSectionRow}>
                <Text style={mainStyles.detailsSectionTitle}>References</Text>
                {userReferences.map((reference, index) => (
                  <ReferenceTemplate data={reference} key={index} />
                ))}
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default ResumePDFTemplate;
