import { toast } from 'react-toastify';
import BaseReducer from '../../utilities/base-reducer';
import JobsAction from './jobs-action';

export default class JobsReducer extends BaseReducer {
  initialState = {
    jobs: []
  };

  [JobsAction.REQUEST_JOBS_FINISHED](state, action) {
    return { ...state, jobs: action.payload };
  }

  [JobsAction.REQUEST_ADD_JOBPOST_FINISHED](state, action) {
    toast.success('Job created successfully');
    return { ...state, jobs: [...state.jobs, action.payload] };
  }

  [JobsAction.REQUEST_EDIT_JOBPOST_FINISHED](state, action) {
    toast.success('Job updated successfully');
    return {
      ...state,
      jobs: state.jobs.map((job) => (job.id === action.payload.id ? action.payload : job))
    };
  }

  [JobsAction.REQUEST_DELETE_JOBPOST_FINISHED](state, action) {
    toast.success('Removed job successfully');
    return { ...state, jobs: state.jobs.filter(({ id }) => id !== action.payload.id) };
  }

  [JobsAction.REQUEST_INVITE_FINISHED](state, action) {
    toast.success('Sent invite request');
    return {
      ...state,
      jobs: state.jobs.map((job) => (job.id === action.payload.id ? action.payload : job))
    };
  }

  [JobsAction.REQUEST_RESUME_FINISHED](state, action) {
    toast.success('Sent resume request');
    return {
      ...state,
      jobs: state.jobs.map((job) => (job.id === action.payload.id ? action.payload : job))
    };
  }

  [JobsAction.REQUEST_APPLY_FINISHED](state, action) {
    toast.success('Applied successfully');
    return {
      ...state,
      jobs: state.jobs.map((job) => (job.id === action.payload.id ? action.payload : job))
    };
  }

  [JobsAction.REQUEST_DECLINE_FINISHED](state, action) {
    toast.success('Declined successfully');
    return {
      ...state,
      jobs: state.jobs.map((job) => (job.id === action.payload.id ? action.payload : job))
    };
  }

  [JobsAction.REQUEST_REJECT_FINISHED](state, action) {
    toast.success('Rejected successfully');
    return {
      ...state,
      jobs: state.jobs.map((job) => (job.id === action.payload.id ? action.payload : job))
    };
  }

  [JobsAction.REQUEST_HIRE_FINISHED](state, action) {
    toast.success('Hired successfully');
    return {
      ...state,
      jobs: state.jobs.map((job) => (job.id === action.payload.id ? action.payload : job))
    };
  }

  [JobsAction.REQUEST_CANCEL_FINISHED](state, action) {
    toast.success('Cancelled successfully');
    return {
      ...state,
      jobs: state.jobs.map((job) => (job.id === action.payload.id ? action.payload : job))
    };
  }
}
