import React from 'react';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { FormGroupContainer, FormGroup, TextField, Button, Select } from '@duik/it';
import Icon from '@duik/icon';
import DropdownMenu from '../../../../../components/dropdown-menu/dropdown-menu';
import { selectEducations } from '../../../../../shared/selectors/education/education-selector';
import EducationsAction from '../../../../../store/educations/educations-action';
import { getMonthOption, getStateOption, months, states } from '../../../../../shared/sample-data';
import StatesSelect from '../../../../../components/states-select/states-select';
import Loader from '../../../../../components/loader/loader';
import cls from './education-details.module.scss';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  city: Yup.string().required('This field is required'),
  state: Yup.object({
    value: Yup.string().required('This field is required')
  }),
  program: Yup.string().required('This field is required'),
  accomplishments: Yup.array().min(1, 'This field is required'),
  startYear: Yup.number()
    .required('Required')
    .integer('Integer Only')
    .test('MinMax Test', '', (value) => value >= 1970 && value <= 2050),
  endYear: Yup.number()
    .required('Required')
    .integer('Integer Only')
    .test('MinMax Test', '', (value) => value >= 1970 && value <= 2050)
});

const mapStateToProps = (state) => ({
  educations: selectEducations(state)
});

const EducationDetails = ({ educationId, educations, dispatch }) => {
  const submitForm = (values) => {
    if (educationId === 'new-education') {
      dispatch(
        EducationsAction.requestCreateEducation({
          name: values.name,
          city: values.city,
          state: values.state.value,
          program: values.program,
          degree: values.degree,
          major: values.major,
          minor: values.minor,
          accomplishments: values.accomplishments.map((a) => a.accomplishment),
          startMonth: values.startMonth.value,
          startYear: values.startYear,
          endMonth: values.endMonth.value,
          endYear: values.endYear
        })
      );
    } else {
      dispatch(
        EducationsAction.requestUpdateEducation({
          id: educationId,
          name: values.name,
          city: values.city,
          state: values.state.value,
          program: values.program,
          degree: values.degree,
          major: values.major,
          minor: values.minor,
          accomplishments: values.accomplishments.map((a) => a.accomplishment),
          startMonth: values.startMonth.value,
          startYear: values.startYear,
          endMonth: values.endMonth.value,
          endYear: values.endYear
        })
      );
    }
  };

  const education = educations.find(({ id }) => id === educationId);

  const initialValues = {
    name: education ? education.name : '',
    city: education ? education.city : '',
    state: getStateOption(education ? education.state : states[0]),
    program: education ? education.program : '',
    degree: education ? education.degree : '',
    major: education ? education.major : '',
    minor: education ? education.minor : '',
    accomplishments: education
      ? education.accomplishments.map((accomplishment) => ({ id: uuid(), accomplishment }))
      : [],
    startMonth: getMonthOption(education ? education.startMonth : 1),
    startYear: education ? education.startYear : 2010,
    endMonth: getMonthOption(education ? education.endMonth : 12),
    endYear: education ? education.endYear : 2010,
    accomplishment: ''
  };

  return (
    <div className={cls['education-details']} style={{ position: 'relative' }}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitForm}
      >
        {({ values, errors, touched, handleSubmit, handleChange, handleBlur }) => (
          <form onSubmit={handleSubmit}>
            <FormGroupContainer className={cls['section1']}>
              <FormGroup>
                <TextField
                  label="EDUCATION INSTITUTION NAME"
                  placeholder="What is the school's name?"
                  name="name"
                  errorMessage={errors.name && touched.name && errors.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
              </FormGroup>
              <div className={cls['location']}>
                <div className={cls['label']}>EDUCATION INSTITUTION LOCATION</div>
                <div className={cls['inputs']}>
                  <FormGroup style={{ marginTop: '0.3rem' }}>
                    <TextField
                      placeholder="City"
                      name="city"
                      errorMessage={errors.city && touched.city && errors.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city}
                    />
                  </FormGroup>
                  <div style={{ minWidth: '120px', flexGrow: 1, marginTop: '0.3rem' }}>
                    <StatesSelect
                      showLabel={false}
                      values={values}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                </div>
              </div>
              <FormGroup>
                <TextField
                  label="DEGREE PROGRAM &amp; CONCENTRATION"
                  placeholder="What field of study was pursued?"
                  name="program"
                  errorMessage={errors.program && touched.program && errors.program}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.program}
                />
              </FormGroup>
              <FormGroup>
                <TextField
                  label="DEGREE OBTAINED"
                  placeholder="Optional"
                  name="degree"
                  errorMessage={errors.degree && touched.degree && errors.degree}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.degree}
                />
              </FormGroup>
              <FormGroup>
                <TextField
                  label="MAJOR"
                  placeholder="Optional"
                  name="major"
                  errorMessage={errors.major && touched.major && errors.major}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.major}
                />
              </FormGroup>
              <FormGroup>
                <TextField
                  label="MINOR"
                  placeholder="Optional"
                  name="minor"
                  errorMessage={errors.minor && touched.minor && errors.minor}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.minor}
                />
              </FormGroup>
            </FormGroupContainer>
            <div className={cls['section2']}>
              <div className={cls['section2-right']}>
                <FormGroup>
                  <TextField
                    label="HONORS &amp; ACCOMPLISHMENT"
                    placeholder="Enter accomplishment"
                    name="accomplishment"
                    errorMessage={
                      errors.accomplishment && touched.accomplishment && errors.accomplishment
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.accomplishment}
                  />
                </FormGroup>
                <Field name="accomplishments" id="accomplishments">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <>
                      <Button
                        clear
                        className={cls['button']}
                        onClick={(e) => {
                          e.preventDefault();
                          if (values.accomplishment)
                            setFieldValue('accomplishments', [
                              ...value,
                              { id: uuid(), accomplishment: values.accomplishment }
                            ]);
                          setFieldValue('accomplishment', '');
                        }}
                      >
                        <Icon mr>plus_rounded</Icon>Add Accomplishment
                      </Button>
                      <div className={cls['items-list']}>
                        {value.map((data, index) => (
                          <div key={index} className={cls['item']}>
                            <div className={cls['item-label']}>{data.accomplishment}</div>
                            <div
                              className={cls['item-btn']}
                              onClick={(e) => {
                                e.preventDefault();
                                setFieldValue(
                                  'accomplishments',
                                  value.filter((v) => v.id !== data.id)
                                );
                              }}
                            >
                              <Icon>close</Icon>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className={cls['select-error']}>
                        {errors.accomplishments &&
                          touched.accomplishments &&
                          errors.accomplishments}
                      </div>
                    </>
                  )}
                </Field>
              </div>
              <div className={cls['section2-left']}>
                <div className={cls['label']}>Date Range</div>
                <div className={cls['inputs']}>
                  <FormGroup>
                    <Field name="startMonth" id="startMonth">
                      {({ field: { value }, form: { setFieldValue } }) => {
                        return (
                          <DropdownMenu
                            placeholder="Select Month"
                            options={months.map((m, i) => ({ label: m, value: i + 1 }))}
                            activeOption={value}
                            onOptionClick={(option) => {
                              setFieldValue('startMonth', option);
                            }}
                            menuPosition="bottom-left"
                          />
                        );
                      }}
                    </Field>
                  </FormGroup>
                  <FormGroup>
                    <TextField
                      placeholder="Start Year"
                      name="startYear"
                      type="number"
                      min={1970}
                      max={2050}
                      errorMessage={errors.startYear && touched.startYear && errors.startYear}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.startYear}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field name="endMonth" id="endMonth">
                      {({ field: { value }, form: { setFieldValue } }) => {
                        return (
                          <DropdownMenu
                            placeholder="Select Month"
                            options={months.map((m, i) => ({ label: m, value: i + 1 }))}
                            activeOption={value}
                            onOptionClick={(option) => {
                              setFieldValue('endMonth', option);
                            }}
                            menuPosition="bottom-left"
                          />
                        );
                      }}
                    </Field>
                  </FormGroup>
                  <FormGroup>
                    <TextField
                      placeholder="End Year"
                      name="endYear"
                      type="number"
                      min={1970}
                      max={2050}
                      errorMessage={errors.endYear && touched.endYear && errors.endYear}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.endYear}
                    />
                  </FormGroup>
                </div>
                <div className={cls['buttons']}>
                  <Button type="submit" className={cls['update']}>
                    <Icon mr>{educationId === 'new-education' ? 'plus_rounded' : 'cloud_up'}</Icon>
                    {educationId === 'new-education' ? 'Create' : 'Update'}
                  </Button>
                  {educationId && educationId !== 'new-education' && (
                    <Button
                      className={cls['remove']}
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(EducationsAction.requestDeleteEducation(educationId));
                      }}
                    >
                      <Icon mr>close</Icon>Remove
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default connect(mapStateToProps)(EducationDetails);
