import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { selectModal } from '../../shared/selectors/modal/modal-selector';
import ModalAction from '../../store/modal/modal-action';
import { ModalTypes, SecondModalTypes } from '../../store/modal/models/modal-types';

// Main Modals
import JSJobSearchModal from './main/js-job-search-modal/js-job-search-modal';
import JSAdminModal from './main/js-admin-modal/js-admin-modal';
import JSWorkHistoryWizardModal from './main/js-workhistory-wizard-modal/js-workhistory-wizard-modal';
import JSEducationWizardModal from './main/js-education-wizard-modal/js-education-wizard-modal';
import JSReferencesWizardModal from './main/js-references-wizard-modal/js-references-wizard-modal';
import JSFullJobListingModal from './main/js-full-job-listing-modal/js-full-job-listing-modal';
import JPCandidateSearchModal from './main/jp-candidate-search-modal/jp-candidate-search-modal';
import JPCreateJobModal from './main/jp-create-job-modal/jp-create-job-modal';
import JPAdminModal from './main/jp-admin-modal/jp-admin-modal';
import JPRequestInterviewModal from './main/jp-request-interview-modal/jp-request-interview-modal';
import JPRequestResumeModal from './main/jp-request-resume-modal/jp-request-resume-modal';
import JPRequestHireModal from './main/jp-request-hire-modal/jp-request-hire-modal';
import FullResumeModal from './main/full-resume-modal/full-resume-modal';

// Second Modals
import JPCloseJobConfirm from './second/jp-close-job-confirm/jp-close-job-confirm';

import Loader from '../../components/loader/loader';
import { selectRequesting } from '../../shared/selectors/requesting/requesting-selector';
import cls from './modal-container.module.scss';

Modal.setAppElement('#modal-parent');

const mapStateToProps = (state, ownProps) => ({
  modal: selectModal(state),
  requesting: selectRequesting(state)
});

const ModalContainer = ({ modal, dispatch, requesting }) => {
  const handleCloseMainModal = () => {
    dispatch(ModalAction.updateModalState({ isOpen: false, type: ModalTypes.none }));
  };

  const handleCloseSecondModal = () => {
    dispatch(
      ModalAction.updateModalState({
        isSecondOpen: false,
        secondType: SecondModalTypes.none
      })
    );
  };

  useEffect(() => {
    const modalRef = document.querySelector('.ReactModal__Content');
    if (modalRef) {
      modalRef.scrollTop = 0;
    }
  }, [modal.type]);

  const maxHeight = window.innerHeight - 96;

  return (
    <>
      <Modal
        isOpen={modal.isOpen}
        onRequestClose={handleCloseMainModal}
        className={cls['main-modal']}
        shouldCloseOnOverlayClick={false}
        style={{ content: { maxHeight } }}
      >
        {requesting && <Loader />}
        {/* JS Modals */}
        {(modal.type === ModalTypes.jsJobSearch || modal.type === ModalTypes.jsActiveJobs) && (
          <JSJobSearchModal onClose={handleCloseMainModal} />
        )}
        {modal.type === ModalTypes.jsAdmin && <JSAdminModal onClose={handleCloseMainModal} />}
        {modal.type === ModalTypes.jsWorkHistoryWizard && (
          <JSWorkHistoryWizardModal onClose={handleCloseMainModal} />
        )}
        {modal.type === ModalTypes.jsEducationWizard && (
          <JSEducationWizardModal onClose={handleCloseMainModal} />
        )}
        {modal.type === ModalTypes.jsReferencesWizard && (
          <JSReferencesWizardModal onClose={handleCloseMainModal} />
        )}
        {modal.type === ModalTypes.jsFullJobListing && (
          <JSFullJobListingModal onClose={handleCloseMainModal} />
        )}
        {/* JP Modals */}
        {modal.type === ModalTypes.jpCandidateSearch && (
          <JPCandidateSearchModal onClose={handleCloseMainModal} candidateId={modal.candidateId} />
        )}
        {(modal.type === ModalTypes.jpCreateJob || modal.type === ModalTypes.jpActiveJobs) && (
          <JPCreateJobModal onClose={handleCloseMainModal} />
        )}
        {modal.type === ModalTypes.jpAdmin && <JPAdminModal onClose={handleCloseMainModal} />}
        {modal.type === ModalTypes.JPRequestInterview && (
          <JPRequestInterviewModal onClose={handleCloseMainModal} />
        )}
        {modal.type === ModalTypes.JPRequestResume && (
          <JPRequestResumeModal onClose={handleCloseMainModal} />
        )}
        {modal.type === ModalTypes.JPRequestHire && (
          <JPRequestHireModal onClose={handleCloseMainModal} />
        )}
        {modal.type === ModalTypes.fullResume && <FullResumeModal onClose={handleCloseMainModal} />}
      </Modal>
      <Modal
        isOpen={modal.isSecondOpen}
        onRequestClose={handleCloseSecondModal}
        className={cls['second-modal']}
        shouldCloseOnOverlayClick={false}
        style={{ content: { maxHeight } }}
      >
        {modal.secondType === SecondModalTypes.jpCloseJobConfirm && (
          <JPCloseJobConfirm onClose={handleCloseSecondModal} />
        )}
      </Modal>
    </>
  );
};

export default connect(mapStateToProps)(ModalContainer);
