const baseApi = 'api';

const ApiRoutes = {
  signin: `/auth/signin`,
  signupSeeker: `/auth/signup-seeker`,
  signupProvider: `/auth/signup-provider`,
  confirmEmail: `/auth/confirm-email`,
  resetPassword: `/auth/reset-password`,
  changePassword: `/auth/change-password`,
  user: `/auth/user`,
  config: `/auth/config`,
  jobType: `${baseApi}/job-type`,
  license: `${baseApi}/license`,
  jobRole: `${baseApi}/job-role`,
  segment: `${baseApi}/segment`,
  educationLevel: `${baseApi}/education-level`,
  job: `${baseApi}/job`,
  candidate: `${baseApi}/candidate`,
  provider: `${baseApi}/provider`,
  workHistory: `${baseApi}/work-history`,
  education: `${baseApi}/education`,
  reference: `${baseApi}/reference`
};

export default ApiRoutes;
