import { toast } from 'react-toastify';
import BaseReducer from '../../utilities/base-reducer';
import WorkHistoriesAction from './work-histories-action';

export default class WorkHistoriesReducer extends BaseReducer {
  initialState = {
    workHistories: []
  };

  [WorkHistoriesAction.REQUEST_WORKHISTORIES_FINISHED](state, action) {
    return { ...state, workHistories: action.payload };
  }

  [WorkHistoriesAction.REQUEST_CREATE_WORKHISTORY_FINISHED](state, action) {
    toast.success('Work history added successfully');
    return { ...state, workHistories: [...state.workHistories, action.payload] };
  }

  [WorkHistoriesAction.REQUEST_UPDATE_WORKHISTORY_FINISHED](state, action) {
    toast.success('Work history updated successfully');
    return {
      ...state,
      workHistories: state.workHistories.map((w) =>
        w.id === action.payload.id ? action.payload : w
      )
    };
  }

  [WorkHistoriesAction.REQUEST_DELETE_WORKHISTORY_FINISHED](state, action) {
    toast.success('Work history removed successfully');
    return {
      ...state,
      workHistories: state.workHistories.filter(({ id }) => id !== action.payload.id)
    };
  }
}
