import ActionUtility from '../../utilities/action-utility';
import ReferencesEffect from './references-effect';

export default class ReferencesAction {
  static REQUEST_REFERENCES = 'ReferencesAction.REQUEST_REFERENCES';
  static REQUEST_REFERENCES_FINISHED = 'ReferencesAction.REQUEST_REFERENCES_FINISHED';
  static requestReferences() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReferencesAction.REQUEST_REFERENCES,
        ReferencesEffect.requestReferences
      );
    };
  }

  static REQUEST_CREATE_REFERENCE = 'ReferencesAction.REQUEST_CREATE_REFERENCE';
  static REQUEST_CREATE_REFERENCE_FINISHED = 'ReferencesAction.REQUEST_CREATE_REFERENCE_FINISHED';
  static requestCreateReference(data) {
    return async (dispatch, action) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReferencesAction.REQUEST_CREATE_REFERENCE,
        ReferencesEffect.requestCreateReference,
        data
      );
    };
  }

  static REQUEST_UPDATE_REFERENCE = 'ReferencesAction.REQUEST_UPDATE_REFERENCE';
  static REQUEST_UPDATE_REFERENCE_FINISHED = 'ReferencesAction.REQUEST_UPDATE_REFERENCE_FINISHED';
  static requestUpdateReference(data) {
    return async (dispatch, action) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReferencesAction.REQUEST_UPDATE_REFERENCE,
        ReferencesEffect.requestUpdateReference,
        data
      );
    };
  }

  static REQUEST_DELETE_REFERENCE = 'ReferencesAction.REQUEST_DELETE_REFERENCE';
  static REQUEST_DELETE_REFERENCE_FINISHED = 'ReferencesAction.REQUEST_DELETE_REFERENCE_FINISHED';
  static requestDeleteReference(id) {
    return async (dispatch, action) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReferencesAction.REQUEST_DELETE_REFERENCE,
        ReferencesEffect.requestDeleteReference,
        id
      );
    };
  }
}
