import { createSelector } from 'reselect';

export const selectAuth = createSelector(
  (state) => state.auth,
  (r) => r
);

export const selectUser = createSelector(
  (state) => state.auth.user,
  (r) => r
);

export const selectConfig = createSelector(
  (state) => state.auth.config,
  (r) => r
);

export const selectAuthenticated = createSelector(
  (state) => state.auth.isAuthenticated,
  (r) => r
);
