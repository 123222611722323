import BaseReducer from '../../utilities/base-reducer';
import ProvidersAction from './providers-action';

export default class Providers extends BaseReducer {
  initialState = {
    providers: []
  };

  [ProvidersAction.REQUEST_PROVIDERS_FINISHED](state, action) {
    return { ...state, providers: action.payload };
  }
}
