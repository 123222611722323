import React from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox } from '@duik/it';
import Icon from '@duik/icon';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import Divider from '../../../../components/divider/divider';
import ModalAction from '../../../../store/modal/modal-action';
import JobsAction from '../../../../store/jobs/jobs-action';
import { selectModal } from '../../../../shared/selectors/modal/modal-selector';
import { selectJobs } from '../../../../shared/selectors/jobs/jobs-selector';
import { selectUser } from '../../../../shared/selectors/auth/auth-selector';
import cls from './jp-request-resume-modal.module.scss';
import { selectRequesting } from '../../../../shared/selectors/requesting/requesting-selector';

const validationSchema = Yup.object().shape({
  jobId: Yup.string().required('You should select a job to send request.')
});

const mapStateToProps = (state) => ({
  requesting: selectRequesting(state, [JobsAction.REQUEST_RESUME]),
  user: selectUser(state),
  modal: selectModal(state),
  jobs: selectJobs(state)
});

const JPRequestResumeModal = ({ onClose, modal, user, jobs, dispatch, requesting }) => {
  const { candidateId } = modal;
  const activeJobs = jobs.filter(
    (job) => job.jobOwner === user.id && job.appliedUsers.length === 0
  );

  const submitForm = (values) => {
    dispatch(JobsAction.requestResume({ jobId: values.jobId, candidateId }));
  };

  const initialValues = {
    jobId: ''
  };

  return (
    <div className={cls['jp-request-interview-modal']}>
      <div className={cls['header']}>
        <h2 className={cls['header-title']}>Request Resume</h2>
        <Button
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
          className={cls['header-icon']}
          square
        >
          <Icon>close</Icon>
        </Button>
      </div>
      <Divider />
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitForm}
      >
        {({ errors, touched, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className={cls['jobs-list']}>
              <div className={cls['jos-list-header']}>
                <div className={cls['header-name']}>Your Active Jobs</div>
              </div>
              <Field name="jobId" id="jobId">
                {({ field: { value }, form: { setFieldValue } }) => (
                  <>
                    {activeJobs.map((job, index) => (
                      <div key={index} className={cls['job-item']}>
                        <div className={cls['name']}>{job.name}</div>
                        <Checkbox
                          key={index}
                          className={cls['check-label']}
                          color="blue"
                          onClick={() => {
                            setFieldValue('jobId', job.id);
                          }}
                          onChange={() => {}}
                          checked={value === job.id}
                        />
                      </div>
                    ))}
                    <div className={cls['select-error']}>
                      {errors.jobId && touched.jobId && errors.jobId}
                    </div>
                  </>
                )}
              </Field>
            </div>
            <div className={cls['footer']}>
              <Button
                type="submit"
                className={cls['submit']}
                loading={requesting}
                disabled={requesting}
              >
                <Icon mr>rocket</Icon>Send Request
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default connect(mapStateToProps)(JPRequestResumeModal);
