import { BaseModel } from 'sjs-base-model';

/*
    // Returned Api Data Sample
    {
      
    }
 */
export default class ReferenceResponseModel extends BaseModel {
  id = '';
  name = '';
  relationship = '';
  email = '';
  companyName = '';
  city = '';
  state = '';
  phoneNumber = '';

  constructor(data) {
    super();

    this.update(data);
  }

  update(data) {
    this.id = data._id;

    super.update(data);
  }
}
