import React from 'react';
import { months } from '../../../../../shared/sample-data';
import cls from './education.module.scss';

const Education = ({ data }) => {
  return (
    <div className={cls['education-content']}>
      <div className={cls['education-column1']}>
        <div>
          <span>NAME:</span> {data ? data.name : ''}
        </div>
        <div>
          <span>LOCATION:</span> {data ? data.city + ', ' + data.state : ''}
        </div>
        <div>
          <span>DEGREE OBTAINED:</span> {data && data.degree ? data.degree : ''}
        </div>
        <div>
          <span>DATE RANGE:</span>{' '}
          {`${months[data.startMonth - 1]} ${data.startYear} to ${months[data.endMonth - 1]} ${
            data.endYear
          }`}
        </div>
      </div>
      <div className={cls['education-column2']}>
        <div>DEGREE PROGRAM AND CONCENTRATION</div>
        {data
          ? data.accomplishments.map((accomplishment, index) => (
              <div key={index}>{accomplishment}</div>
            ))
          : ''}
      </div>
    </div>
  );
};

export default Education;
