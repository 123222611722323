// Test
export { default as t01 } from './test/t01.jpg';
export { default as a23 } from './test/a23.jpg';

// Test profile icon
export { default as profile } from './png/profile.png';
export { default as profile1 } from './png/profile1.jpg';

export { default as tarLogo } from './svg/tar-logo.svg';

// Avatars
export { default as avatar1 } from './svg/avatars/avatar1.svg';

// Signin
export { default as signinFacebook } from './svg/oauth-facebook.svg';
export { default as signinGoogle } from './svg/oauth-google.svg';
