import React from 'react';
import cls from './slider.module.scss';

export const SliderTypes = {
  years: 'years',
  dollar: 'dollar',
  miles: 'miles',
  number: 'number'
};

const Slider = ({ style, min, max, value, type, title, onChange, disabled }) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  let display = '';
  if (type === SliderTypes.years) {
    display = value + ' Years';
  }
  if (type === SliderTypes.dollar) {
    display = '$' + value;
  }
  if (type === SliderTypes.miles) {
    display = value + ' Miles';
  }
  if (type === SliderTypes.number) {
    display = value;
  }

  return (
    <div className={cls['slider']} style={{ ...style }}>
      <div className={cls['slider-header']}>
        <div className={cls['slider-title']}>{title}</div>
        <div className={cls['slider-display']}>{display}</div>
      </div>
      <div className={cls['slider-bar']}>
        <input
          type="range"
          min={min}
          max={max}
          value={value}
          className="slider"
          onChange={handleChange}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default Slider;
