import ActionUtility from '../../utilities/action-utility';
import CandidatesEffect from './candidates-effect';

export default class CandidatesAction {
  static REQUEST_CANDIDATES = 'CandidatesAction.REQUEST_CANDIDATES';
  static REQUEST_CANDIDATES_FINISHED = 'CandidatesAction.REQUEST_CANDIDATES_FINISHED';
  static requestCandidates() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CandidatesAction.REQUEST_CANDIDATES,
        CandidatesEffect.requestCandidates
      );
    };
  }

  static REQUEST_UPDATE_CANDIDATE = 'CandidatesAction.REQUEST_UPDATE_CANDIDATE';
  static REQUEST_UPDATE_CANDIDATE_FINISHED = 'CandidatesAction.REQUEST_UPDATE_CANDIDATE_FINISHED';
  static requestUpdateCandidate(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        CandidatesAction.REQUEST_UPDATE_CANDIDATE,
        CandidatesEffect.requestUpdateCandidate,
        data
      );
    };
  }
}
