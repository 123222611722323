import ApiRoutes from '../../shared/api-routes/api-routes';
import EffectUtility from '../../utilities/effect-utility';
import ReferenceResponseModel from './models/reference-response-model';
import DeleteResponseModel from '../../shared/models/delete-response-model';

export default class ReferencesEffect {
  static async requestReferences() {
    return EffectUtility.getToModel(ReferenceResponseModel, `${ApiRoutes.reference}`);
  }

  static async requestCreateReference(data) {
    return EffectUtility.postToModel(ReferenceResponseModel, `${ApiRoutes.reference}/add`, data);
  }

  static async requestUpdateReference(data) {
    return EffectUtility.postToModel(ReferenceResponseModel, `${ApiRoutes.reference}/edit`, data);
  }

  static async requestDeleteReference(id) {
    return EffectUtility.deleteToModel(DeleteResponseModel, `${ApiRoutes.reference}/${id}`);
  }
}
