import BaseReducer from '../../utilities/base-reducer';
import MenuAction from './menu-action';

export default class MenuReducer extends BaseReducer {
  initialState = {
    sidebarOpen: false
  };

  [MenuAction.UPDATE_MENU_STATE](state, action) {
    return { ...state, ...action.payload };
  }
}
