import { BaseModel } from 'sjs-base-model';

/*
    // Returned Api Data Sample
    {
      
    }
 */
export default class UpdateAvatarResponseModel extends BaseModel {
  profilePic = '';

  constructor(data) {
    super();

    this.update(data);
  }

  update(data) {
    super.update(data);

    // this.role = data.ROLE;
  }
}
