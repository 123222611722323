import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { referenceStyles } from './styles';

const ReferenceTemplate = ({ data }) => {
  return (
    <View style={referenceStyles.reference}>
      <View style={referenceStyles.column}>
        <Text style={referenceStyles.item}>NAME: {data.name}</Text>
        <Text style={referenceStyles.item}>RELATIONSHIP: {data.relationship}</Text>
      </View>
      <View style={referenceStyles.column}>
        <Text style={referenceStyles.item}>COMPANY NAME: {data.companyName}</Text>
        <Text style={referenceStyles.item}>LOCATION: {data.city + ', ' + data.state}</Text>
      </View>
      <View style={referenceStyles.column}>
        <Text style={referenceStyles.item}>E-MAIL: {data.email}</Text>
        <Text style={referenceStyles.item}>PHONE NUMBER: {data.phoneNumber}</Text>
      </View>
    </View>
  );
};

export default ReferenceTemplate;
