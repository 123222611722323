import BaseReducer from '../../utilities/base-reducer';
import { ModalTypes, SecondModalTypes } from './models/modal-types';
import ModalAction from './modal-action';

export default class ModalReducer extends BaseReducer {
  initialState = {
    isOpen: false,
    type: ModalTypes.none,
    candidateId: '',
    jobId: '',
    isSecondOpen: false,
    secondType: SecondModalTypes.none
    // isOpen: true,
    // type: ModalTypes.jsReferencesWizard
    // candidateId: '5f3b96e18f8e3b4594719da0',
    // jobId: '5f40d2082d82d158fcd02f55';
  };

  [ModalAction.UPDATE_MODAL_STATE](state, action) {
    return { ...state, ...action.payload };
  }
}
