import { BaseModel } from 'sjs-base-model';

/*
    // Returned Api Data Sample
    {
      
    }
 */
export default class UserResponseModel extends BaseModel {
  id = '';
  firstName = '';
  lastName = '';
  address = '';
  addressLine = '';
  country = '';
  state = '';
  city = '';
  zip = '';
  location = {};
  jobTypes = [];
  jobRoles = [];
  headline = '';
  segments = [];
  licenses = [];
  educationLevel = '';
  experience = 0;
  minSalaryReq = 0;
  maxCommute = 0;
  currentWorkHistory = '';
  previousWorkHistories = [];
  educations = [];
  references = [];
  email = '';
  phoneNumber = '';
  profilePic = {};
  openedProfile = false;
  // for provider
  companyName = '';
  companyWebsite = '';
  firstName = '';
  lastName = '';
  phoneNumber = '';
  email = '';
  segments = [];
  employees = 0;

  constructor(data) {
    super();

    this.update(data);
  }

  update(data) {
    super.update(data);

    // this.role = data.ROLE;
  }
}
