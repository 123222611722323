export const jobCandidateStatus = {
  interviewRequested: 'interviewRequested',
  resumeRequested: 'resumeRequested',
  applied: 'applied',
  declined: 'declined',
  rejected: 'rejected',
  hired: 'hired'
};

export const jobCandidateStatusData = {
  interviewRequested: 'Interview Requested',
  resumeRequested: 'Resume Requested',
  applied: 'Applied',
  declined: 'Declined',
  rejected: 'Rejected',
  hired: 'Hired'
};
