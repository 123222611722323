import { createSelector } from 'reselect';

export class RequestingSelector {
  static selectRequesting(requestingState, actionTypes) {
    if (actionTypes && actionTypes.length > 0) {
      return actionTypes.some((actionType) => requestingState[actionType]);
    }

    return (
      Object.keys(requestingState).filter((rs) => {
        return !!requestingState[rs];
      }).length > 0
    );
  }
}

export const selectRequesting = createSelector(
  (state) => state.requesting,
  (state, actionTypes) => actionTypes,
  RequestingSelector.selectRequesting
);
