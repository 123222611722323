import { BaseModel } from 'sjs-base-model';

/*
    // Returned Api Data Sample
    {
      id: "5f2092422a646daefb290142"
      name: "Account Executive / Specialist"
      order: 1
    }
 */
export default class LicenseResponseModel extends BaseModel {
  id = '';
  name = '';
  order = 1;

  constructor(data) {
    super();

    this.update(data);
  }

  update(data) {
    super.update(data);

    this.id = data._id;
    // this.role = data.ROLE;
  }
}
