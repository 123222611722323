import { routes } from '../routes';

export const sortOptions = [
  {
    label: 'Name',
    value: 'name'
  },
  {
    label: 'Date',
    value: 'date'
  }
];

export const jsMenuOptions = [
  { label: 'Job Search', path: routes.jsJobSearch.path },
  { label: 'Active Job Listings', path: routes.jsActiveJobs.path },
  { label: 'Archived Job Listings', path: routes.jsArchivedJobs.path },
  { label: 'Candidate Profile', path: 'js-admin' },
  { label: 'Log Out', path: 'logout' }
];

export const jpMenuOptions = [
  { label: 'Candidate Search', path: routes.jpCandidateSearch.path },
  { label: 'Active Job Listings', path: routes.jpActiveJobs.path },
  { label: 'Archived Job Listings', path: routes.jpArchivedJobs.path },
  { label: 'Job Listing Templates', path: routes.jpTemplates.path },
  { label: 'Create Job Listing', path: 'jp-create-job' },
  { label: 'Employer Profile', path: 'jp-admin' },
  { label: 'Log Out', path: 'logout' }
];

// DO NOT DELETE
export const testUsers = [
  { email: 'test@test.com' },
  { email: 'jaylan.shanah@gmail.com' },
  { email: 'carolyn.herz@gmail.com' },
  { email: 'ottilie1995@hotmail.com' },
  { email: 'zackary1997@hotmail.com' },
  { email: 'marlin1997@gmail.com' },
  { email: 'dameon2005@gmail.com' },
  { email: 'stanford1996@hotmail.com' }
];

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const getMonthOption = (value) => {
  const month = months[value - 1];
  return { label: month, value: value };
};

export const states = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming'
];

export const getStateOption = (state) => {
  return { label: state, value: state };
};
