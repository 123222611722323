import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Button } from '@duik/it';
import Icon from '@duik/icon';
import Divider from '../../../../components/divider/divider';
import EmployerDetails from './employer-details/employer-details';
import { selectUser } from '../../../../shared/selectors/auth/auth-selector';
import { selectRequesting } from '../../../../shared/selectors/requesting/requesting-selector';
import WorkHistoriesAction from '../../../../store/work-histories/work-histories-action';
import AuthAction from '../../../../store/auth/auth-action';
import ModalAction from '../../../../store/modal/modal-action';
import { ModalTypes } from '../../../../store/modal/models/modal-types';
import cls from './js-workhistory-wizard-modal.module.scss';

const mapStateToProps = (state) => ({
  requesting: selectRequesting(state, [
    WorkHistoriesAction.REQUEST_CREATE_WORKHISTORY,
    WorkHistoriesAction.REQUEST_UPDATE_WORKHISTORY,
    WorkHistoriesAction.REQUEST_DELETE_WORKHISTORY
  ]),
  user: selectUser(state)
});

const JSWorkHistoryWizardModal = ({ onClose, user, requesting, dispatch }) => {
  const [currentHistory, setCurrentHistory] = useState('');
  const [previousHistories, setPreviousHistories] = useState([]);

  useMemo(() => {
    if (user) {
      setCurrentHistory(user.currentWorkHistory);
      setPreviousHistories(user.previousWorkHistories);
    }
  }, [user]);

  useMemo(() => {
    if (!requesting) {
      dispatch(AuthAction.requestUser());
    }
  }, [requesting]);

  return (
    <div className={cls['js-workhistory-wizard-modal']}>
      <div className={cls['header']}>
        <h2 className={cls['header-title']}>Add Work History</h2>
        <div className={cls['header-right']}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              onClose();
            }}
            className={cls['close-icon']}
            square
          >
            <Icon>close</Icon>
          </Button>
        </div>
      </div>
      <Divider />
      <div className={cls['content']}>
        <div className={cls['title']}>CURRENT EMPLOYER</div>
        {currentHistory && <EmployerDetails isCurrentUser historyId={currentHistory} />}
        {!currentHistory && (
          <div style={{ width: '100%', margin: '1rem 0' }}>
            <Button
              className={cls['add']}
              onClick={(e) => {
                e.preventDefault();
                setCurrentHistory('new-history');
              }}
            >
              <Icon mr>plus_rounded</Icon>Add Employer
            </Button>
          </div>
        )}
        <div className={cls['title']}>PREVIOUS EMPLOYERS</div>
        <div className="pt-2">
          If you've had multiple positions with a single employer, please use the 'Add Employer'
          button for each separate position, in order of most recent to oldest position.
        </div>
        {previousHistories.map((history, index) => (
          <EmployerDetails historyId={history} key={index} />
        ))}
        <div style={{ width: '100%', margin: '1rem 0' }}>
          <Button
            className={cls['add']}
            onClick={(e) => {
              e.preventDefault();
              setPreviousHistories([...previousHistories, 'new-history']);
            }}
          >
            <Icon mr>plus_rounded</Icon>Add Employer
          </Button>
        </div>
      </div>
      <Divider />
      <div className={cls['footer']}>
        <Button
          className={cls['next']}
          onClick={(e) => {
            e.preventDefault();
            dispatch(
              ModalAction.updateModalState({ isOpen: true, type: ModalTypes.jsEducationWizard })
            );
          }}
        >
          <Icon mr>arrow_right</Icon>Next
        </Button>
        <Button
          className={cls['cancel']}
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
        >
          <Icon mr>close</Icon>Cancel
        </Button>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(JSWorkHistoryWizardModal);
