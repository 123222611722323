import ApiRoutes from '../../shared/api-routes/api-routes';
import EffectUtility from '../../utilities/effect-utility';
import EducationResponseModel from './models/education-response-model';
import DeleteResponseModel from '../../shared/models/delete-response-model';

export default class EducationsEffect {
  static async requestEducations() {
    return EffectUtility.getToModel(EducationResponseModel, `${ApiRoutes.education}`);
  }

  static async requestCreateEducation(data) {
    return EffectUtility.postToModel(EducationResponseModel, `${ApiRoutes.education}/add`, data);
  }

  static async requestUpdateEducation(data) {
    return EffectUtility.postToModel(EducationResponseModel, `${ApiRoutes.education}/edit`, data);
  }

  static async requestDeleteEducation(id) {
    return EffectUtility.deleteToModel(DeleteResponseModel, `${ApiRoutes.education}/${id}`);
  }
}
