import React from 'react';
import { months } from '../../../../../shared/sample-data';
import cls from './work-history.module.scss';

const WorkHistory = ({ isCurrentClient, data }) => {
  return (
    <div className={cls['work-history-content']}>
      <div className={cls['work-history-column1']}>
        <div>
          <span>EMPLOYER NAME:</span> {data ? data.employerName : ''}
        </div>
        <div>
          <span>JOB TITLE:</span> {data ? data.jobTitle : ''}
        </div>
        <div>
          <span>JOB LOCATION:</span> {data ? data.city + ', ' + data.state : ''}
        </div>
        <div>
          <span>DATE RANGE:</span> {`${months[data.startMonth - 1]} ${data.startYear}`}
          {!isCurrentClient && ` to ${months[data.endMonth - 1]} ${data.endYear}`}
        </div>
      </div>
      <div className={cls['work-history-column2']}>
        <div>ESSENTIAL JOB DUTIES &amp; RESPONSIBILITIES</div>
        {data ? data.duties.map((duty, index) => <div key={index}>{duty}</div>) : ''}
      </div>
    </div>
  );
};

export default WorkHistory;
