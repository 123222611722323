import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, TextField } from '@duik/it';
import { Formik, Field } from 'formik';
import { push } from 'connected-react-router';
import * as Yup from 'yup';
import { default as AvatarEdit } from 'react-avatar-edit';
import Icon from '@duik/icon';
import Divider from '../../../../components/divider/divider';
import FormCheckbox from '../../../../components/form-checkbox/form-checkbox';
import Slider, { SliderTypes } from '../../../../components/slider/slider';
import { selectFilters } from '../../../../shared/selectors/filters/filters-selector';
import { selectUser } from '../../../../shared/selectors/auth/auth-selector';
import AuthAction from '../../../../store/auth/auth-action';
import ModalAction from '../../../../store/modal/modal-action';
import { ModalTypes } from '../../../../store/modal/models/modal-types';
import { selectRequesting } from '../../../../shared/selectors/requesting/requesting-selector';
import { getStateOption, states } from '../../../../shared/sample-data';
import StatesSelect from '../../../../components/states-select/states-select';
import cls from './jp-admin-modal.module.scss';
import { routes } from '../../../../routes';

const mapStateToProps = (state) => ({
  requestingUpdateAvatar: selectRequesting(state, [AuthAction.REQUEST_UPDATE_AVATAR]),
  requestingUpdateProvider: selectRequesting(state, [AuthAction.REQUEST_UPDATE_PROVIDER]),
  filters: selectFilters(state),
  user: selectUser(state)
});

const JPAdminModal = ({
  onClose,
  filters,
  user,
  requestingUpdateAvatar,
  requestingUpdateProvider,
  dispatch
}) => {
  const [image, setImage] = useState({ preview: null, src: null });

  const segmentOptions = filters.segments.map((t) => ({ id: t.id, name: t.name }));

  useEffect(() => {
    if (user && !user.openedProfile) {
      dispatch(AuthAction.requestUpdateProvider({ ...user, openedProfile: true }));
    }
  }, [dispatch, user]);

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required('This field is required'),
    address: Yup.string().required('This field is required'),
    country: Yup.string().required('This field is required'),
    state: Yup.object({
      value: Yup.string().required('This field is required')
    }),
    city: Yup.string().required('This field is required'),
    zip: Yup.string().required('This field is required'),
    firstName: Yup.string().required('This field is required'),
    lastName: Yup.string().required('This field is required'),
    phoneNumber: Yup.string().required('This field is required'),
    email: Yup.string().required('This field is required').email('Invalid Email'),
    segments: Yup.array().min(1, 'This field is required')
  });

  const submitForm = (values) => {
    let request = {
      id: user.id,
      companyName: values.companyName,
      address: values.address,
      addressLine: values.addressLine,
      country: values.country,
      city: values.city,
      state: values.state.value,
      zip: values.zip,
      companyWebsite: values.companyWebsite,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      email: values.email,
      segments: values.segments,
      employees: values.employees
    };

    dispatch(AuthAction.requestUpdateProvider(request));
  };

  const initialValues = {
    companyName: user ? user.companyName : '',
    address: user ? user.address : '',
    addressLine: user ? user.addressLine : '',
    country: user ? user.country : '',
    city: user ? user.city : '',
    state: getStateOption(user ? user.state : states[0]),
    zip: user ? user.zip : '',
    companyWebsite: user ? user.companyWebsite : '',
    firstName: user ? user.firstName : '',
    lastName: user ? user.lastName : '',
    phoneNumber: user ? user.phoneNumber : '',
    email: user ? user.email : '',
    segments: user ? user.segments : [],
    employees: user ? user.employees : 10
  };

  const onCrop = (preview) => setImage((p) => ({ ...p, preview }));

  const onCloseAvatarEdit = () => {
    setImage({ preview: null, src: null });
  };

  const uploadAvatar = () => {
    if (image.preview) {
      dispatch(AuthAction.requestUpdateAvatar({ file: image.preview }));
    }
  };

  return (
    <div className={cls['jp-admin-modal']}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitForm}
      >
        {({ values, errors, touched, handleSubmit, handleChange, handleBlur }) => (
          <form onSubmit={handleSubmit}>
            <div className={cls['header']}>
              <h2 className={cls['header-title']}>Employer Profile</h2>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  onClose();
                }}
                className={cls['header-icon']}
                square
              >
                <Icon>close</Icon>
              </Button>
            </div>
            <Divider />
            <div className={cls['content']}>
              <div className={cls['title-section']}>
                <div className={cls['titles']}>
                  <div className={cls['header-inputs']}>
                    <TextField
                      style={{ width: '12rem' }}
                      label="COMPANY NAME"
                      name="companyName"
                      errorMessage={errors.companyName && touched.companyName && errors.companyName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.companyName}
                    />
                  </div>
                  <div className={cls['title']}>
                    <TextField
                      label="Address"
                      name="address"
                      errorMessage={errors.address && touched.address && errors.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address}
                    />
                  </div>
                  <div className={cls['title']}>
                    <TextField
                      label="COUNTRY"
                      name="country"
                      errorMessage={errors.country && touched.country && errors.country}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.country}
                    />
                    <TextField
                      label="ADDRESS LINE 2"
                      name="addressLine"
                      errorMessage={errors.addressLine && touched.addressLine && errors.addressLine}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.addressLine}
                    />
                  </div>
                  <div className={cls['title']}>
                    <TextField
                      label="CITY"
                      name="city"
                      errorMessage={errors.city && touched.city && errors.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city}
                    />
                  </div>
                  <div className={cls['title']}>
                    <StatesSelect values={values} errors={errors} touched={touched} />
                    <TextField
                      label="ZIP CODE"
                      name="zip"
                      type="number"
                      errorMessage={errors.zip && touched.zip && errors.zip}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.zip}
                    />
                  </div>
                </div>
                <div className={cls['avatar']}>
                  {requestingUpdateAvatar ? (
                    <div style={{ textAlign: 'center' }}>Updating...</div>
                  ) : (
                    <AvatarEdit
                      width={300}
                      height={200}
                      minCropRadius={50}
                      onCrop={onCrop}
                      onClose={onCloseAvatarEdit}
                      src={image.src}
                    />
                  )}
                  <Button
                    className={cls['update']}
                    onClick={(e) => {
                      e.preventDefault();
                      uploadAvatar();
                    }}
                    disabled={requestingUpdateAvatar}
                  >
                    <Icon mr>cloud_up</Icon>Save
                  </Button>
                </div>
              </div>
              <div className={cls['fields-section']}>
                <div className={cls['field']}>
                  <TextField
                    label="COMPANY WEBSITE"
                    name="companyWebsite"
                    errorMessage={
                      errors.companyWebsite && touched.companyWebsite && errors.companyWebsite
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.companyWebsite}
                  />
                  <TextField
                    label="FIRST NAME"
                    name="firstName"
                    errorMessage={errors.firstName && touched.firstName && errors.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                  />
                  <TextField
                    label="LAST NAME"
                    name="lastName"
                    errorMessage={errors.lastName && touched.lastName && errors.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                  />
                </div>
                <div className={cls['field']}>
                  <TextField
                    label="CONTACT PHONE NUMBER"
                    name="phoneNumber"
                    errorMessage={errors.phoneNumber && touched.phoneNumber && errors.phoneNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phoneNumber}
                  />
                  <TextField
                    label="CONTACT EMAIL"
                    name="email"
                    errorMessage={errors.email && touched.email && errors.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                </div>
              </div>
              <div className={cls['props-section']}>
                <div className={cls['column']}>
                  <div className={cls['title']}>Insurance Segment</div>
                  <Field name="segments" id="segments">
                    {({ field: { value }, form: { setFieldValue } }) => (
                      <>
                        {segmentOptions.map((data, index) => (
                          <FormCheckbox
                            key={index}
                            data={data}
                            formValue={value}
                            setFieldValue={setFieldValue}
                            fieldName="segments"
                          />
                        ))}
                        <div className={cls['select-error']}>
                          {errors.segments && touched.segments && errors.segments}
                        </div>
                      </>
                    )}
                  </Field>
                </div>
                <div className={cls['column']}>
                  <Field name="employees" id="employees">
                    {({ field: { value }, form: { setFieldValue } }) => (
                      <Slider
                        title="Number of Employees"
                        type={SliderTypes.number}
                        value={value}
                        min={1}
                        max={200}
                        onChange={(val) => {
                          setFieldValue('employees', Number(val));
                        }}
                      />
                    )}
                  </Field>
                </div>
                <div className={cls['column']}>
                  <Button
                    type="submit"
                    className={cls['action-btn']}
                    loading={requestingUpdateProvider}
                  >
                    <Icon mr>profile_card</Icon>Update Profile
                  </Button>
                  <Button
                    className={cls['action-btn']}
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(
                        ModalAction.updateModalState({ isOpen: true, type: ModalTypes.jpCreateJob })
                      );
                    }}
                  >
                    <Icon mr>view_list</Icon>Create new Job Listing
                  </Button>
                  <Button
                    className={cls['action-btn']}
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(push(routes.jpActiveJobs.path));
                      dispatch(ModalAction.updateModalState({ isOpen: false }));
                    }}
                  >
                    <Icon mr>inbox_paper_round</Icon>View Active Job Listings
                  </Button>
                  <Button
                    className={cls['action-btn']}
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(push(routes.jpTemplates.path));
                      dispatch(ModalAction.updateModalState({ isOpen: false }));
                    }}
                  >
                    <Icon mr>rocket</Icon>View Job Listing Templates
                  </Button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default connect(mapStateToProps)(JPAdminModal);
