import ApiRoutes from '../../shared/api-routes/api-routes';
import EffectUtility from '../../utilities/effect-utility';
import WorkHistoryResponseModel from './models/work-history-response-model';
import DeleteResponseModel from '../../shared/models/delete-response-model';

export default class WorkHistoriesEffect {
  static async requestWorkHistories() {
    return EffectUtility.getToModel(WorkHistoryResponseModel, `${ApiRoutes.workHistory}`);
  }

  static async requestCreateWorkHistory(data) {
    return EffectUtility.postToModel(
      WorkHistoryResponseModel,
      `${ApiRoutes.workHistory}/add`,
      data
    );
  }

  static async requestUpdateWorkHistory(data) {
    return EffectUtility.postToModel(
      WorkHistoryResponseModel,
      `${ApiRoutes.workHistory}/edit`,
      data
    );
  }

  static async requestDeleteWorkHistory(id, historyType) {
    return EffectUtility.deleteToModel(
      DeleteResponseModel,
      `${ApiRoutes.workHistory}/${id}/${historyType}`
    );
  }
}
