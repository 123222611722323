import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@duik/it';
import Icon from '@duik/icon';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import Divider from '../../../../components/divider/divider';
import WorkHistory from './work-history/work-history';
import Education from './education/education';
import Reference from './reference/reference';
import ResumePDFTemplate from './resume-pdf-template/resume-pdf-template';
import { selectCandidates } from '../../../../shared/selectors/candidate/candidate-selector';
import { selectFilters } from '../../../../shared/selectors/filters/filters-selector';
import { selectModal } from '../../../../shared/selectors/modal/modal-selector';
import { selectAuth } from '../../../../shared/selectors/auth/auth-selector';
import { UserTypes } from '../../../../shared/constants/user-types';
import { selectWorkHistories } from '../../../../shared/selectors/work-history/work-history-selector';
import { selectEducations } from '../../../../shared/selectors/education/education-selector';
import { selectReferences } from '../../../../shared/selectors/reference/reference-selector';
import ModalAction from '../../../../store/modal/modal-action';
import { ModalTypes } from '../../../../store/modal/models/modal-types';
import { selectJobs } from '../../../../shared/selectors/jobs/jobs-selector';
import cls from './full-resume-modal.module.scss';
import { jobCandidateStatus } from '../../../../shared/constants/job-candidate-status';

const mapStateToProps = (state) => ({
  auth: selectAuth(state),
  candidates: selectCandidates(state),
  filters: selectFilters(state),
  modal: selectModal(state),
  workHistories: selectWorkHistories(state),
  educations: selectEducations(state),
  references: selectReferences(state),
  jobs: selectJobs(state)
});

const FullResumeModal = ({
  onClose,
  candidates,
  filters,
  modal,
  auth,
  workHistories,
  educations,
  references,
  jobs,
  dispatch
}) => {
  const user =
    auth.role === UserTypes.seeker
      ? auth.user
      : auth.role === UserTypes.provider
      ? candidates.find((c) => c.id === modal.candidateId)
      : null;

  const managements =
    user && filters
      ? user.jobRoles.reduce((total, current, index) => {
          const management = filters.jobRoles.find((role) => role.id === current);
          return total.concat(
            management ? (index === 0 ? management.name : `, ${management.name}`) : ''
          );
        }, '')
      : '';
  const educationLevel =
    filters && user ? filters.educationLevels.find((e) => e.id === user.educationLevel) : '';

  const jobTypes =
    user && filters
      ? user.jobTypes.map((jt) => {
          const jobType = filters.jobTypes.find((t) => t.id === jt);
          return jobType ? jobType.name : '';
        })
      : [];

  const segments =
    user && filters
      ? user.segments.map((jt) => {
          const segment = filters.segments.find((t) => t.id === jt);
          return segment ? segment.name : '';
        })
      : [];

  const licenses =
    user && filters
      ? user.licenses.map((jt) => {
          const license = filters.licenses.find((t) => t.id === jt);
          return license ? license.name : '';
        })
      : [];

  const userCurrentWorkHistory =
    user && workHistories ? workHistories.find(({ id }) => id === user.currentWorkHistory) : null;
  const userPreviousWorkHistories =
    user && workHistories
      ? user.previousWorkHistories.map((p) => workHistories.find(({ id }) => id === p))
      : [];
  const userEducations =
    user && educations ? user.educations.map((e) => educations.find(({ id }) => id === e)) : [];
  const userReferences =
    user && references ? user.references.map((e) => references.find(({ id }) => id === e)) : [];

  const userName = user ? user.firstName + ' ' + user.lastName : '';

  const showInfo =
    auth.role === UserTypes.provider && jobs && user
      ? jobs
          .filter((job) => job.jobOwner === auth.user.id)
          .some((job) =>
            job.appliedUsers.some(
              (au) =>
                au.candidateId.toString() === user.id && au.status === jobCandidateStatus.applied
            )
          )
      : false;

  const downloadPDF = async () => {
    const blob = await pdf(
      <ResumePDFTemplate
        data={{
          userName,
          email: user ? user.email : '',
          phoneNumber: user ? user.phoneNumber : '',
          address: user ? [user.address, user.city, user.state, user.zip].join(', ') : '',
          maxCommute: user ? user.maxCommute : '',
          minSalaryReq: user ? user.minSalaryReq : '',
          managements: managements,
          description: user ? user.headline : '',
          education: educationLevel.name,
          experience: user ? user.experience : '',
          jobTypes,
          segments,
          licenses,
          userCurrentWorkHistory,
          userPreviousWorkHistories,
          userEducations,
          userReferences
        }}
        showInfo={showInfo}
      />
    ).toBlob();
    saveAs(blob, [userName, 'Full Resume.pdf'].join('-'));
  };

  return (
    <div className={cls['full-resume-modal']}>
      <div className={cls['header']}>
        <h2 className={cls['header-title']}>{userName}</h2>
        {showInfo && (
          <div className={cls['header-info']}>
            <div>E-MAIL ADDRESS: {user ? user.email : ''}</div>
            <div>PHONE NUMBER: {user ? user.phoneNumber : ''}</div>
          </div>
        )}
        <Button
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
          className={cls['header-icon']}
          square
        >
          <Icon>close</Icon>
        </Button>
      </div>
      <Divider />
      <div className={cls['content']}>
        <div className={cls['title-section']}>
          <div className={cls['titles']}>
            <div className={cls['title']}>{user ? user.address : ''}</div>
            <div className={cls['title']}>Maximum Commute: {user ? user.maxCommute : ''} Miles</div>
            <div className={cls['title']}>Minium Salary: ${user ? user.minSalaryReq : ''}</div>
            <div className={cls['title']}>{user ? managements : ''}</div>
          </div>
          <div className={cls['desc-section']}>
            <div className={cls['desc-title']}>DESCRIPTION / OBJECTIVE</div>
            {user && user.headline && <div className={cls['description']}>{user.headline}</div>}
          </div>
        </div>
        <div className={cls['years-section']}>
          <div className={cls['years']}>Education: {educationLevel.name}</div>
          <div className={cls['years']}>Experience: {user ? user.experience : ''} years</div>
        </div>
        <div className={cls['props-section']}>
          <div className={cls['props-column']}>
            <div className={cls['props-column-title']}>Job Type</div>
            {jobTypes.map((jt, i) => (
              <div key={i} className={cls['props-column-text']}>
                {jt}
              </div>
            ))}
          </div>
          <div className={cls['props-column']}>
            <div className={cls['props-column-title']}>Insurance Segment</div>
            {segments.map((sg, i) => (
              <div key={i} className={cls['props-column-text']}>
                {sg}
              </div>
            ))}
          </div>
          <div className={cls['props-column']}>
            <div className={cls['props-column-title']}>Current Licensing</div>
            {licenses.map((ls, i) => (
              <div key={i} className={cls['props-column-text']}>
                {ls}
              </div>
            ))}
          </div>
        </div>
        <div className={cls['details-section']}>
          <div className={cls['details-section-row']}>
            <div className={cls['details-section-title']}>Work History</div>
            {userCurrentWorkHistory && (
              <WorkHistory isCurrentClient data={userCurrentWorkHistory} />
            )}
            {userPreviousWorkHistories &&
              userPreviousWorkHistories.map((history, index) => (
                <WorkHistory data={history} key={index} />
              ))}
          </div>
          <div className={cls['details-section-row']}>
            <div className={cls['details-section-title']}>Education</div>
            {userEducations &&
              userEducations.map((education, index) => <Education data={education} key={index} />)}
          </div>
          <div className={cls['details-section-row']}>
            <div className={cls['details-section-title']}>References</div>
            {userReferences &&
              userReferences.map((reference, index) => <Reference data={reference} key={index} />)}
          </div>
        </div>
      </div>
      <div className={cls['footer']}>
        <Button
          className={cls['update']}
          onClick={(e) => {
            e.preventDefault();
            if (userName) {
              downloadPDF();
            }
          }}
        >
          <Icon mr>profile_card</Icon>Download Resume
        </Button>
        {auth.role === UserTypes.seeker && (
          <Button
            className={cls['builder']}
            onClick={(e) => {
              e.preventDefault();
              dispatch(
                ModalAction.updateModalState({
                  isOpen: true,
                  type: ModalTypes.jsWorkHistoryWizard
                })
              );
            }}
          >
            <Icon mr>trending_up</Icon>
            Update Resume
          </Button>
        )}
        <Button
          className={cls['view']}
          onClick={(e) => {
            e.preventDefault();
            dispatch(
              ModalAction.updateModalState(
                auth.role === UserTypes.seeker
                  ? {
                      isOpen: true,
                      type: ModalTypes.jsAdmin
                    }
                  : {
                      isOpen: true,
                      type: ModalTypes.jpCandidateSearch,
                      candidateId: modal.candidateId
                    }
              )
            );
          }}
        >
          <Icon mr>arrow_left</Icon>
          {auth.role === UserTypes.seeker ? 'Candidate Profile' : 'Back'}
        </Button>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(FullResumeModal);
