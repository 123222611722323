import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Button } from '@duik/it';
import Icon from '@duik/icon';
import Avatar from 'react-avatar';
import Swal from 'sweetalert2';
import Divider from '../../../../components/divider/divider';
import { selectModal } from '../../../../shared/selectors/modal/modal-selector';
import { selectJobs } from '../../../../shared/selectors/jobs/jobs-selector';
import { selectFilters } from '../../../../shared/selectors/filters/filters-selector';
import { selectProviders } from '../../../../shared/selectors/provider/provider-selector';
import ModalAction from '../../../../store/modal/modal-action';
import { ModalTypes } from '../../../../store/modal/models/modal-types';
import JobsAction from '../../../../store/jobs/jobs-action';
import * as images from '../../../../assets/images/index';
import { selectUser } from '../../../../shared/selectors/auth/auth-selector';
import { jobCandidateStatus } from '../../../../shared/constants/job-candidate-status';
import { selectRequesting } from '../../../../shared/selectors/requesting/requesting-selector';
import cls from './js-job-search-modal.module.scss';

const mapStateToProps = (state) => ({
  requesting: selectRequesting(state, [JobsAction.REQUEST_APPLY, JobsAction.REQUEST_DECLINE]),
  user: selectUser(state),
  modal: selectModal(state),
  jobs: selectJobs(state),
  filters: selectFilters(state),
  providers: selectProviders(state)
});

const JSJobSearchModal = ({
  onClose,
  user,
  modal,
  jobs,
  filters,
  providers,
  dispatch,
  requesting
}) => {
  const { jobId } = modal;
  const jobData = jobs.find((j) => j.id === jobId);

  const owner = jobData ? providers.find((p) => p.id === jobData.jobOwner) : '';
  const management = jobData ? filters.jobRoles.find((jr) => jr.id === jobData.management) : '';
  const educationLevel = jobData
    ? filters.educationLevels.find((el) => el.id === jobData.reqEducation)
    : '';

  const jobTypes = jobData
    ? jobData.jobTypes.map((jt) => {
        const jobType = filters.jobTypes.find((t) => t.id === jt);
        return jobType.name;
      })
    : [];

  const segments = jobData
    ? jobData.segments.map((jt) => {
        const segment = filters.segments.find((t) => t.id === jt);
        return segment.name;
      })
    : [];

  const licenses = jobData
    ? jobData.licenses.map((jt) => {
        const license = filters.licenses.find((t) => t.id === jt);
        return license.name;
      })
    : [];

  const appliedUser =
    jobData && user ? jobData.appliedUsers.find((au) => au.candidateId === user.id) : null;

  // We consider resume is built if user have at least one of work history, education, reference.
  const resumeBuilt =
    user &&
    (user.previousWorkHistories.length > 0 ||
      user.currentWorkHistory ||
      user.educations.length > 0 ||
      user.references.length > 0);

  const canDecline =
    appliedUser &&
    (appliedUser.status === jobCandidateStatus.interviewRequested ||
      appliedUser.status === jobCandidateStatus.resumeRequested);

  const canApply = appliedUser
    ? appliedUser.status === jobCandidateStatus.interviewRequested ||
      appliedUser.status === jobCandidateStatus.resumeRequested
    : true;

  return (
    <div className={cls['js-job-search-modal']}>
      <div className={cls['header']}>
        <h2 className={cls['header-title']}>{jobData ? jobData.name : ''}</h2>
        <Button
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
          className={cls['header-icon']}
          square
        >
          <Icon>close</Icon>
        </Button>
      </div>
      <Divider />
      <div className={cls['content']}>
        <div className={cls['title-section']}>
          <div className={cls['titles']}>
            <div className={cls['title']}>Company Name: {owner ? owner.companyName : ''}</div>
            <div className={cls['title']}>Location: {jobData ? jobData.address : ''}</div>
            <div className={cls['title']}>{management ? management.name : ''}</div>
            <div className={cls['title']}>
              {jobData.displayToCandidate
                ? `${jobData.isHourlyRate ? 'Hourly Rate Range' : 'Salary Range'}: $${
                    jobData.isHourlyRate ? jobData.hourlyRateRange.min : jobData.salaryRange.min
                  } - $${
                    jobData.isHourlyRate ? jobData.hourlyRateRange.max : jobData.salaryRange.max
                  }`
                : 'Meets MSR'}
            </div>
          </div>
          <div className={cls['avatar']}>
            <Avatar
              src={
                owner && owner.profilePic && owner.profilePic.location
                  ? owner.profilePic.location
                  : images.profile1
              }
              size={160}
            />
          </div>
        </div>
        <div className={cls['desc-section']}>
          <div className={cls['desc-title']}>DESCRIPTION</div>
          {jobData && jobData.shortDesc && (
            <div className={cls['description']}>{jobData.shortDesc}</div>
          )}
        </div>
        <div className={cls['years-section']}>
          <div className={cls['years']}>
            Required Education: {educationLevel ? educationLevel.name : ''}
          </div>
          <div className={cls['years']}>
            Required Experience: {jobData ? jobData.reqExp : ''} Years
          </div>
        </div>
        <div className={cls['props-section']}>
          <div className={cls['column']}>
            <div className={cls['column-title']}>Job Type</div>
            {jobTypes &&
              jobTypes.map((jt, i) => (
                <div key={i} className={cls['column-text']}>
                  {jt}
                </div>
              ))}
          </div>
          <div className={cls['column']}>
            <div className={cls['column-title']}>Insurance Segment</div>
            {segments &&
              segments.map((s, i) => (
                <div key={i} className={cls['column-text']}>
                  {s}
                </div>
              ))}
          </div>
          <div className={cls['column']}>
            <div className={cls['column-title']}>Required Licensing</div>
            {licenses &&
              licenses.map((ls, i) => (
                <div key={i} className={cls['column-text']}>
                  {ls}
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className={cls['footer']}>
        <div className={cls['buttons']}>
          {canApply && (
            <Button
              className={cls['apply']}
              onClick={(e) => {
                e.preventDefault();
                if (resumeBuilt) {
                  if (jobData) {
                    dispatch(JobsAction.requestApply({ jobId: jobData.id, candidateId: user.id }));
                  }
                } else {
                  Swal.fire({
                    text: `You must build your resume before you can apply 
                    for a job. Go to your Candidate Profile and click on the 
                    "Build Resume" button.  This will take you through the resume 
                    builder wizard. Once you have built your resume, 
                    you can immediately begin applying for jobs.`
                  });
                }
              }}
              loading={requesting}
              disabled={requesting}
            >
              <Icon mr>plus_rounded</Icon>Apply Now
            </Button>
          )}
          {canDecline && (
            <Button
              className={cls['decline']}
              onClick={(e) => {
                e.preventDefault();
                if (jobData && user) {
                  dispatch(JobsAction.requestDecline({ jobId: jobData.id, candidateId: user.id }));
                }
              }}
              loading={requesting}
              disabled={requesting}
            >
              <Icon mr>wallet</Icon>Decline Job
            </Button>
          )}
          {appliedUser &&
            appliedUser.status !== jobCandidateStatus.interviewRequested &&
            appliedUser.status !== jobCandidateStatus.resumeRequested && (
              <div className={classnames(cls['status'], cls[appliedUser.status])}>
                {appliedUser.status}
              </div>
            )}
          <Button
            className={cls['view']}
            onClick={(e) => {
              e.preventDefault();
              dispatch(
                ModalAction.updateModalState({
                  isOpen: true,
                  type: ModalTypes.jsFullJobListing,
                  jobId
                })
              );
            }}
          >
            <Icon mr>trending_up</Icon>View Full Job Listing
          </Button>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(JSJobSearchModal);
