import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import classnames from 'classnames';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import {
  WidgetTable,
  Tabs,
  TabItem,
  Select,
  Button,
  Checkbox,
  FormGroup,
  TextField,
  TextArea,
  ScrollArea
} from '@duik/it';
import Icon from '@duik/icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import Divider from '../../../../components/divider/divider';
import Slider, { SliderTypes } from '../../../../components/slider/slider';
import FormCheckbox from '../../../../components/form-checkbox/form-checkbox';
import { selectAuth } from '../../../../shared/selectors/auth/auth-selector';
import { selectJobs } from '../../../../shared/selectors/jobs/jobs-selector';
import { selectFilters } from '../../../../shared/selectors/filters/filters-selector';
import JobsAction from '../../../../store/jobs/jobs-action';
import ModalAction from '../../../../store/modal/modal-action';
import { ModalTypes, SecondModalTypes } from '../../../../store/modal/models/modal-types';
import { selectModal } from '../../../../shared/selectors/modal/modal-selector';
import { selectCandidates } from '../../../../shared/selectors/candidate/candidate-selector';
import { selectRequesting } from '../../../../shared/selectors/requesting/requesting-selector';
import { getStateOption, states } from '../../../../shared/sample-data';
import { jobCandidateStatus } from '../../../../shared/constants/job-candidate-status';
import StatesSelect from '../../../../components/states-select/states-select';
import cls from './jp-create-job-modal.module.scss';
import { routes } from '../../../../routes';

const mapStateToProps = (state, ownProps) => ({
  requestingAdd: selectRequesting(state, [JobsAction.REQUEST_ADD_JOBPOST]),
  requestingEdit: selectRequesting(state, [JobsAction.REQUEST_EDIT_JOBPOST]),
  auth: selectAuth(state),
  jobs: selectJobs(state),
  filters: selectFilters(state),
  modal: selectModal(state),
  candidates: selectCandidates(state)
});

const JPCreateJobModal = ({
  auth,
  onClose,
  dispatch,
  jobs,
  candidates,
  filters,
  modal,
  requestingAdd,
  requestingEdit
}) => {
  const [mainTab, setMainTab] = useState('details');
  const [salaryTab, setSalaryTab] = useState('year');
  const [save, setSave] = useState(false);

  const { type, jobId } = modal;

  const job = jobs.find((j) => j.id === jobId);
  const owner = auth.user;

  const jobCandidates =
    job && candidates
      ? job.appliedUsers
          .filter((au) => {
            return (
              au.status === jobCandidateStatus.applied || au.status === jobCandidateStatus.hired
            );
          })
          .reduce((result, data) => {
            const candidate = candidates.find((c) => {
              return c.id === data.candidateId;
            });

            if (candidate) {
              return [...result, { ...candidate, status: data.status }];
            }
          }, [])
      : [];

  useMemo(() => {
    if (job) {
      setSave(type === ModalTypes.jpActiveJobs ? job.reusable : false);
      setSalaryTab(job.isHourlyRate ? 'hourly' : 'year');
    }
  }, [job]);

  const jobTypeOptions = filters.jobTypes.map((t) => ({ id: t.id, name: t.name }));
  const licenseOptions = filters.licenses.map((t) => ({ id: t.id, name: t.name }));
  const jobRoleOptions = filters.jobRoles.map((t) => ({ id: t.id, name: t.name }));
  const segmentOptions = filters.segments.map((t) => ({ id: t.id, name: t.name }));
  const educationLevelOptions = filters.educationLevels.map((t) => ({
    value: t.id,
    label: t.name
  }));

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('This field is required.'),
    address: Yup.string().required('This field is required'),
    country: Yup.string().required('This field is required'),
    state: Yup.object({
      value: Yup.string().required('This field is required')
    }),
    city: Yup.string().required('This field is required'),
    zip: Yup.number().required('This field is required'),
    summary: Yup.string().required('This field is required.'),
    jobTypes: Yup.array().min(1, 'This field is required'),
    management: Yup.string().required('This field is required.'),
    salaryRangeMin: Yup.number()
      .required('This field is required.')
      .min(1, 'Should be larger than 1')
      .max(350000, 'Should be less than 350000'),
    salaryRangeMax: Yup.number()
      .required('This field is required.')
      .min(1, 'Should be larger than 1')
      .max(350000, 'Should be less than 350000')
      .moreThan(Yup.ref('salaryRangeMin'), 'Should be greater than Min'),
    hourlyRateRangeMin: Yup.number()
      .required('This field is required.')
      .min(1, 'Should be larger than 1')
      .max(300, 'Should be less than 300'),
    hourlyRateRangeMax: Yup.number()
      .required('This field is required.')
      .min(1, 'Should be larger than 1')
      .max(300, 'Should be less than 300')
      .moreThan(Yup.ref('hourlyRateRangeMin'), 'Should be greater than Min'),
    experience: Yup.number().required('This field is required.'),
    maxCommute: Yup.number().required('This field is required.'),
    educationLevel: Yup.object().required('This field is required'),
    segments: Yup.array().min(1, 'This field is required'),
    licenses: Yup.array().min(1, 'This field is required')
  });

  const submitForm = (values) => {
    if (type === ModalTypes.jpCreateJob) {
      dispatch(
        JobsAction.requestAddJobPost({
          title: values.title,
          address: values.address,
          addressLine: values.addressLine || '',
          country: values.country,
          state: values.state.value,
          city: values.city,
          zip: values.zip,
          summary: values.summary,
          jobTypes: values.jobTypes,
          management: values.management,
          salaryRange: { min: values.salaryRangeMin, max: values.salaryRangeMax },
          hourlyRateRange: { min: values.hourlyRateRangeMin, max: values.hourlyRateRangeMax },
          displayRate: values.displayRate,
          isHourlyRate: salaryTab === 'hourly',
          experience: values.experience,
          maxCommute: values.maxCommute,
          educationLevel: values.educationLevel.value,
          segments: values.segments,
          licenses: values.licenses,
          jobDuties: values.jobDuties,
          skills: values.skills,
          requirements: values.requirements,
          additionalNotes: values.additionalNotes,
          reusable: save
        })
      );

      dispatch(push(routes.jpActiveJobs.path));
      dispatch(ModalAction.updateModalState({ isOpen: false }));
    } else if (type === ModalTypes.jpActiveJobs) {
      let request = {
        id: job.id,
        title: values.title,
        address: values.address,
        addressLine: values.addressLine || '',
        country: values.country,
        state: values.state.value,
        city: values.city,
        zip: values.zip,
        summary: values.summary,
        jobTypes: values.jobTypes,
        management: values.management,
        salaryRange: { min: values.salaryRangeMin, max: values.salaryRangeMax },
        hourlyRateRange: { min: values.hourlyRateRangeMin, max: values.hourlyRateRangeMax },
        displayRate: values.displayRate,
        isHourlyRate: salaryTab === 'hourly',
        experience: values.experience,
        maxCommute: values.maxCommute,
        educationLevel: values.educationLevel.value,
        segments: values.segments,
        licenses: values.licenses,
        jobDuties: values.jobDuties,
        skills: values.skills,
        requirements: values.requirements,
        additionalNotes: values.additionalNotes,
        reusable: save
      };

      dispatch(push(routes.jpActiveJobs.path));
      dispatch(JobsAction.requestEditJobPost(request));
      dispatch(ModalAction.updateModalState({ isOpen: false }));
    }
  };

  const initialValues = {
    title: job ? job.name : '',
    address: job ? job.address : owner ? owner.address : '',
    addressLine: job ? job.addressLine : owner ? owner.addressLine : '',
    country: job ? job.country : owner ? owner.country : '',
    state: getStateOption(job ? job.state : owner ? owner.state : states[0]),
    city: job ? job.city : owner ? owner.city : '',
    zip: job ? job.zip : owner ? owner.zip : '',
    summary: job ? job.shortDesc : '',
    jobTypes: job ? job.jobTypes : [],
    management: job ? job.management : '',
    salaryRangeMin: job ? job.salaryRange.min : 40000,
    salaryRangeMax: job ? job.salaryRange.max : 50000,
    hourlyRateRangeMin: job ? job.hourlyRateRange.min : 40,
    hourlyRateRangeMax: job ? job.hourlyRateRange.max : 50,
    displayRate: job ? job.displayToCandidate : true,
    experience: job ? job.reqExp : 1,
    maxCommute: job ? job.maxCommute : 100,
    educationLevel: job
      ? educationLevelOptions.find((el) => el.value === job.reqEducation)
      : undefined,
    segments: job ? job.segments : [],
    licenses: job ? job.licenses : [],
    jobDuty: '',
    jobDuties: job ? job.jobDuties : [],
    skill: '',
    skills: job ? job.skills : [],
    requirement: '',
    requirements: job ? job.requirements : [],
    additionalNotes: job ? job.additionalNotes : ''
  };

  const isCandidatesTab = type === ModalTypes.jpCreateJob ? false : mainTab === 'candidates';
  const showTemplateCheckbox =
    mainTab === 'details' &&
    job &&
    job.appliedUsers &&
    job.appliedUsers.filter((au) => au.status === jobCandidateStatus.hired).length === 0;

  const hired =
    job &&
    job.appliedUsers &&
    job.appliedUsers.filter((au) => au.status === jobCandidateStatus.hired).length > 0;

  return (
    <div className={cls['jp-create-job-modal']}>
      <div className={cls['header']}>
        {type === ModalTypes.jpCreateJob ? (
          <h2 className={cls['header-title']}>Create a New Job Listing</h2>
        ) : (
          <Tabs className={cls['tabs']}>
            <TabItem
              className={classnames(cls['tab-item'], {
                active: mainTab === 'details'
              })}
              onClick={() => {
                setMainTab('details');
              }}
              active
            >
              Job Details
            </TabItem>
            <TabItem
              className={classnames(cls['tab-item'], {
                active: mainTab === 'candidates'
              })}
              onClick={() => {
                setMainTab('candidates');
              }}
            >
              Candidates
            </TabItem>
          </Tabs>
        )}
        <div className={cls['header-right']}>
          {showTemplateCheckbox && (
            <Checkbox
              label="Save as a job listing template"
              checked={save || false}
              onChange={() => {
                setSave(!save);
              }}
            />
          )}
          <Button
            onClick={(e) => {
              e.preventDefault();
              onClose();
            }}
            className={cls['close-icon']}
            square
          >
            <Icon>close</Icon>
          </Button>
        </div>
      </div>
      {type === ModalTypes.jpCreateJob && <Divider />}
      {!isCandidatesTab ? (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitForm}
        >
          {({ values, errors, touched, handleSubmit, handleChange, handleBlur }) => (
            <form onSubmit={handleSubmit}>
              <div className={cls['content']}>
                <div className={cls['title-section']}>
                  <div className={cls['left-column']}>
                    <FormGroup>
                      <TextField
                        label="JOB TITLE"
                        name="title"
                        placeholder="What is the title of this position?"
                        errorMessage={errors.title && touched.title && errors.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                        disabled={hired}
                      />
                    </FormGroup>
                    <FormGroup>
                      <TextField
                        label="Address"
                        name="address"
                        errorMessage={errors.address && touched.address && errors.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.address}
                        disabled={hired}
                      />
                    </FormGroup>
                    <FormGroup>
                      <TextField
                        label="Address Line 2"
                        name="addressLine"
                        errorMessage={
                          errors.addressLine && touched.addressLine && errors.addressLine
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.addressLine}
                        disabled={hired}
                      />
                    </FormGroup>
                    <div className={cls['row']}>
                      <FormGroup>
                        <TextField
                          label="Country"
                          name="country"
                          errorMessage={errors.country && touched.country && errors.country}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.country}
                          disabled={hired}
                        />
                      </FormGroup>
                      <StatesSelect
                        values={values}
                        errors={errors}
                        touched={touched}
                        disabled={hired}
                      />
                    </div>
                    <div className={cls['row']}>
                      <FormGroup>
                        <TextField
                          label="City"
                          name="city"
                          errorMessage={errors.city && touched.city && errors.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.city}
                          disabled={hired}
                        />
                      </FormGroup>
                      <FormGroup>
                        <TextField
                          label="Zip"
                          name="zip"
                          type="number"
                          errorMessage={errors.zip && touched.zip && errors.zip}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.zip}
                          disabled={hired}
                        />
                      </FormGroup>
                    </div>
                  </div>

                  <div className={cls['right-column']}>
                    <TextArea
                      style={{ height: '136px' }}
                      label="JOB SUMMARY"
                      name="summary"
                      placeholder="Enter brief job summary description"
                      errorMessage={errors.summary && touched.summary && errors.summary}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.summary}
                      disabled={hired}
                    />
                    <Field name="experience" id="experience">
                      {({ field: { value }, form: { setFieldValue } }) => (
                        <Slider
                          title="Required Experience"
                          type={SliderTypes.years}
                          value={value}
                          min={1}
                          max={25}
                          onChange={(val) => {
                            setFieldValue('experience', val);
                          }}
                          disabled={hired}
                        />
                      )}
                    </Field>
                    <Field name="maxCommute" id="maxCommute">
                      {({ field: { value }, form: { setFieldValue } }) => (
                        <Slider
                          title="Maximum Commute"
                          type={SliderTypes.miles}
                          value={value}
                          min={1}
                          max={100}
                          onChange={(val) => {
                            setFieldValue('maxCommute', val);
                          }}
                          disabled={hired}
                        />
                      )}
                    </Field>
                    <Field name="educationLevel" id="educationLevel">
                      {({ field: { value }, form: { setFieldValue } }) => {
                        return (
                          <>
                            <Select
                              label="Education Level"
                              placeholder="Required Education Level"
                              menuPosition="top-left"
                              options={educationLevelOptions}
                              activeOption={value}
                              onOptionClick={(option, name) => {
                                setFieldValue('educationLevel', option);
                              }}
                              buttonProps={{ disabled: hired }}
                            />
                            <div className={cls['select-error']}>
                              {errors.educationLevel &&
                                touched.educationLevel &&
                                errors.educationLevel}
                            </div>
                          </>
                        );
                      }}
                    </Field>
                  </div>
                </div>

                <div className={cls['props-section']}>
                  <div className={cls['column']}>
                    <div className={cls['title']}>Job Type</div>
                    <Field name="jobTypes" id="jobTypes">
                      {({ field: { value }, form: { setFieldValue } }) => (
                        <>
                          {jobTypeOptions.map((data, index) => (
                            <FormCheckbox
                              key={index}
                              data={data}
                              formValue={value}
                              setFieldValue={setFieldValue}
                              fieldName="jobTypes"
                              disabled={hired}
                            />
                          ))}
                          <div className={cls['select-error']}>
                            {errors.jobTypes && touched.jobTypes && errors.jobTypes}
                          </div>
                        </>
                      )}
                    </Field>
                    <br />
                    <div className={cls['title']}>Job Role</div>
                    <Field name="management" id="management">
                      {({ field: { value }, form: { setFieldValue } }) => (
                        <>
                          {jobRoleOptions.map((data, index) => (
                            <FormCheckbox
                              key={index}
                              data={data}
                              formValue={value}
                              setFieldValue={setFieldValue}
                              fieldName="management"
                              type="radio"
                              disabled={hired}
                            />
                          ))}
                          <div className={cls['select-error']}>
                            {errors.management && touched.management && errors.management}
                          </div>
                        </>
                      )}
                    </Field>
                    <br />
                    <div className={cls['salary-tabs']}>
                      <Button
                        className={classnames(cls['salary-tabs-item'], {
                          [cls['active']]: salaryTab === 'year'
                        })}
                        style={{ width: '7rem', fontSize: '0.8rem' }}
                        onClick={(e) => {
                          e.preventDefault();
                          setSalaryTab('year');
                        }}
                        disabled={hired}
                      >
                        SALARY RANGE
                      </Button>
                      <Button
                        className={classnames(cls['salary-tabs-item'], {
                          [cls['active']]: salaryTab === 'hourly'
                        })}
                        style={{ width: '9rem', fontSize: '0.8rem' }}
                        onClick={(e) => {
                          e.preventDefault();
                          setSalaryTab('hourly');
                        }}
                        disabled={hired}
                      >
                        HOURLY RATE RANGE
                      </Button>
                    </div>
                    <div className={cls['salary-tabs-content']}>
                      {salaryTab === 'year' && (
                        <div className={cls['range-inputs']}>
                          <div className={cls['group']}>
                            <div className={cls['label']}>From</div>
                            <TextField
                              name="salaryRangeMin"
                              type="number"
                              min={1}
                              max={350000}
                              errorMessage={
                                errors.salaryRangeMin &&
                                touched.salaryRangeMin &&
                                errors.salaryRangeMin
                              }
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                              value={values.salaryRangeMin}
                              leftEl={<FontAwesomeIcon icon={faDollarSign} />}
                              disabled={hired}
                            />
                          </div>
                          <div className={cls['group']}>
                            <div className={cls['label']}>To</div>
                            <TextField
                              name="salaryRangeMax"
                              type="number"
                              min={1}
                              max={350000}
                              errorMessage={
                                errors.salaryRangeMax &&
                                touched.salaryRangeMax &&
                                errors.salaryRangeMax
                              }
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                              value={values.salaryRangeMax}
                              leftEl={<FontAwesomeIcon icon={faDollarSign} />}
                              disabled={hired}
                            />
                          </div>
                        </div>
                      )}
                      {salaryTab === 'hourly' && (
                        <div className={cls['range-inputs']}>
                          <div className={cls['group']}>
                            <div className={cls['label']}>From</div>
                            <TextField
                              name="hourlyRateRangeMin"
                              type="number"
                              min={1}
                              max={300}
                              errorMessage={
                                errors.hourlyRateRangeMin &&
                                touched.hourlyRateRangeMin &&
                                errors.hourlyRateRangeMin
                              }
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                              value={values.hourlyRateRangeMin}
                              leftEl={<FontAwesomeIcon icon={faDollarSign} />}
                              disabled={hired}
                            />
                          </div>
                          <div className={cls['group']}>
                            <div className={cls['label']}>To</div>
                            <TextField
                              name="hourlyRateRangeMax"
                              type="number"
                              min={1}
                              max={300}
                              errorMessage={
                                errors.hourlyRateRangeMax &&
                                touched.hourlyRateRangeMax &&
                                errors.hourlyRateRangeMax
                              }
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                              value={values.hourlyRateRangeMax}
                              leftEl={<FontAwesomeIcon icon={faDollarSign} />}
                              disabled={hired}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <Field name="displayRate" id="displayRate">
                      {({ field: { value }, form: { setFieldValue } }) => (
                        <>
                          <Checkbox
                            className={cls['check-label']}
                            color="blue"
                            label="Display to Candidate"
                            onClick={(e) => {
                              setFieldValue('displayRate', !value);
                            }}
                            onChange={() => {}}
                            checked={value}
                            disabled={hired}
                          />
                          <div className={cls['select-error']}>
                            {errors.displayRate && touched.displayRate && errors.displayRate}
                          </div>
                        </>
                      )}
                    </Field>
                    <br />
                  </div>

                  <div className={cls['column']}>
                    <div className={cls['title']}>Insurance Segment</div>
                    <Field name="segments" id="segments">
                      {({ field: { value }, form: { setFieldValue } }) => (
                        <>
                          {segmentOptions.map((data, index) => (
                            <FormCheckbox
                              key={index}
                              data={data}
                              formValue={value}
                              setFieldValue={setFieldValue}
                              fieldName="segments"
                              disabled={hired}
                            />
                          ))}
                          <div className={cls['select-error']}>
                            {errors.segments && touched.segments && errors.segments}
                          </div>
                        </>
                      )}
                    </Field>
                    <br />
                    <div className={cls['title']}>Required Licensing</div>
                    <Field name="licenses" id="licenses">
                      {({ field: { value }, form: { setFieldValue } }) => (
                        <>
                          {licenseOptions.map((data, index) => (
                            <FormCheckbox
                              key={index}
                              data={data}
                              formValue={value}
                              setFieldValue={setFieldValue}
                              fieldName="licenses"
                              disabled={hired}
                            />
                          ))}
                          <div className={cls['select-error']}>
                            {errors.licenses && touched.licenses && errors.licenses}
                          </div>
                        </>
                      )}
                    </Field>
                  </div>

                  <div className={cls['column']}>
                    <FormGroup>
                      {hired ? (
                        <div className={cls['title']}>
                          Essential job duties &amp; responsibilities
                        </div>
                      ) : (
                        <TextField
                          label="ESSENTIAL JOB DUTIES &amp; RESPONSIBILITIES"
                          name="jobDuty"
                          placeholder="Enter job duty"
                          errorMessage={errors.jobDuty && touched.jobDuty && errors.jobDuty}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.jobDuty}
                        />
                      )}
                      <Field name="jobDuties" id="jobDuties">
                        {({ field: { value }, form: { setFieldValue } }) => (
                          <>
                            {!hired && (
                              <Button
                                className={cls['add-btn']}
                                clear
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (values.jobDuty)
                                    setFieldValue('jobDuties', [...value, values.jobDuty]);
                                  setFieldValue('jobDuty', '');
                                }}
                              >
                                <Icon mr>plus_rounded</Icon>Add Job Duty
                              </Button>
                            )}
                            <div className={cls['items-list']}>
                              {value.map((data, index) => (
                                <div key={index} className={cls['item']}>
                                  <div className={cls['item-label']}>{data}</div>
                                  {!hired && (
                                    <div
                                      className={cls['item-btn']}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setFieldValue(
                                          'jobDuties',
                                          value.filter((v) => v !== data)
                                        );
                                      }}
                                    >
                                      <Icon>close</Icon>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                            <div className={cls['select-error']}>
                              {errors.jobDuties && touched.jobDuties && errors.jobDuties}
                            </div>
                          </>
                        )}
                      </Field>
                    </FormGroup>
                    <FormGroup>
                      {hired ? (
                        <div className={cls['title']}>Core competencies &amp; skills</div>
                      ) : (
                        <TextField
                          label="CORE COMPETENCIES &amp; SKILLS"
                          name="skill"
                          placeholder="Enter skill"
                          errorMessage={errors.skill && touched.skill && errors.skill}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.skill}
                        />
                      )}
                      <Field name="skills" id="skills">
                        {({ field: { value }, form: { setFieldValue } }) => (
                          <>
                            {!hired && (
                              <Button
                                className={cls['add-btn']}
                                clear
                                onClick={(e) => {
                                  e.preventDefault();
                                  setFieldValue('skills', [...value, values.skill]);
                                  setFieldValue('skill', '');
                                }}
                              >
                                <Icon mr>plus_rounded</Icon>Add Skill
                              </Button>
                            )}
                            <div className={cls['items-list']}>
                              {value.map((data, index) => (
                                <div key={index} className={cls['item']}>
                                  <div className={cls['item-label']}>{data}</div>
                                  {!hired && (
                                    <div
                                      className={cls['item-btn']}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setFieldValue(
                                          'skills',
                                          value.filter((v) => v !== data)
                                        );
                                      }}
                                    >
                                      <Icon>close</Icon>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                            <div className={cls['select-error']}>
                              {errors.skills && touched.skills && errors.skills}
                            </div>
                          </>
                        )}
                      </Field>
                    </FormGroup>
                    <FormGroup>
                      {hired ? (
                        <div className={cls['title']}>Other requirements</div>
                      ) : (
                        <TextField
                          label="OTHER REQUIREMENTS"
                          name="requirement"
                          placeholder="Enter requirement"
                          errorMessage={
                            errors.requirement && touched.requirement && errors.requirement
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.requirement}
                        />
                      )}
                      <Field name="requirements" id="requirements">
                        {({ field: { value }, form: { setFieldValue } }) => (
                          <>
                            {!hired && (
                              <Button
                                className={cls['add-btn']}
                                clear
                                onClick={(e) => {
                                  e.preventDefault();
                                  setFieldValue('requirements', [...value, values.requirement]);
                                  setFieldValue('requirement', '');
                                }}
                              >
                                <Icon mr>plus_rounded</Icon>Add Requirement
                              </Button>
                            )}
                            <div className={cls['items-list']}>
                              {value.map((data, index) => (
                                <div key={index} className={cls['item']}>
                                  <div className={cls['item-label']}>{data}</div>
                                  {!hired && (
                                    <div
                                      className={cls['item-btn']}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setFieldValue(
                                          'requirements',
                                          value.filter((v) => v !== data)
                                        );
                                      }}
                                    >
                                      <Icon>close</Icon>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                            <div className={cls['select-error']}>
                              {errors.requirements && touched.requirements && errors.requirements}
                            </div>
                          </>
                        )}
                      </Field>
                    </FormGroup>
                    <FormGroup>
                      <TextArea
                        placeholder="Enter additional notes"
                        label="ADDITIONAL NOTES"
                        rows={6}
                        name="additionalNotes"
                        errorMessage={
                          errors.additionalNotes &&
                          touched.additionalNotes &&
                          errors.additionalNotes
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.additionalNotes}
                        disabled={hired}
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
              {!hired && (
                <div className={cls['footer']}>
                  {type === ModalTypes.jpCreateJob ? (
                    <Button
                      type="submit"
                      className={cls['apply']}
                      disabled={requestingAdd}
                      loading={requestingAdd}
                    >
                      <Icon mr>plus_rounded</Icon>Post Job Listing!
                    </Button>
                  ) : (
                    <>
                      <Button
                        type="submit"
                        className={cls['apply']}
                        disabled={requestingEdit}
                        loading={requestingEdit}
                      >
                        <Icon mr>plus_rounded</Icon>Update Job
                      </Button>
                      <Button
                        className={cls['decline']}
                        onClick={(e) => {
                          e.preventDefault();
                          if (jobId) {
                            dispatch(
                              ModalAction.updateModalState({
                                isSecondOpen: true,
                                secondType: SecondModalTypes.jpCloseJobConfirm,
                                jobId
                              })
                            );
                          }
                        }}
                      >
                        <Icon mr>close</Icon>Close Job
                      </Button>
                    </>
                  )}
                </div>
              )}
            </form>
          )}
        </Formik>
      ) : (
        <ScrollArea className={cls['candidates']}>
          <WidgetTable>
            <thead>
              <tr>
                <th>Name</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {jobCandidates &&
                jobCandidates.map((candidate, index) => (
                  <tr key={index}>
                    <td>
                      <code>{[candidate.firstName, candidate.lastName].join(' ')}</code>
                    </td>
                    <td>{candidate.status}</td>
                    <td>
                      <Button
                        className={cls['view-btn']}
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(
                            ModalAction.updateModalState({
                              isOpen: true,
                              type: ModalTypes.jpCandidateSearch,
                              candidateId: candidate.id
                            })
                          );
                        }}
                      >
                        <Icon mr>view_simple</Icon>
                        <div className={cls['btn-text']}>View Profile</div>
                      </Button>
                      {!hired && (
                        <Button
                          className={cls['view-btn']}
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(
                              JobsAction.requestReject({ jobId, candidateId: candidate.id })
                            );
                          }}
                        >
                          <Icon mr>close</Icon>
                          <div className={cls['btn-text']}>Reject</div>
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </WidgetTable>
        </ScrollArea>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(JPCreateJobModal);
