import React from 'react';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { FormGroupContainer, FormGroup, TextField, Select, Button } from '@duik/it';
import Icon from '@duik/icon';
import { selectWorkHistories } from '../../../../../shared/selectors/work-history/work-history-selector';
import WorkHistoriesAction from '../../../../../store/work-histories/work-histories-action';
import { getMonthOption, getStateOption, months, states } from '../../../../../shared/sample-data';
import cls from './employer-details.module.scss';
import StatesSelect from '../../../../../components/states-select/states-select';

const validationSchema = Yup.object().shape({
  employerName: Yup.string().required('This field is required'),
  jobTitle: Yup.string().required('This field is required'),
  duties: Yup.array().min(1, 'This field is required'),
  city: Yup.string().required('Required'),
  state: Yup.object({
    value: Yup.string().required('This field is required')
  }),
  startYear: Yup.number()
    .required('Required')
    .integer('Integer Only')
    .test('MinMax Test', '', (value) => value >= 1970 && value <= 2050),
  endYear: Yup.number()
    .required('Required')
    .integer('Integer Only')
    .test('MinMax Test', '', (value) => value >= 1970 && value <= 2050)
});

const mapStateToProps = (state) => ({
  workHistories: selectWorkHistories(state)
});

const EmployerDetails = ({ isCurrentUser, historyId, workHistories, dispatch }) => {
  const submitForm = (values) => {
    if (historyId === 'new-history') {
      dispatch(
        WorkHistoriesAction.requestCreateWorkHistory({
          isCurrent: !!isCurrentUser,
          employerName: values.employerName,
          jobTitle: values.jobTitle,
          duties: values.duties.map((duty) => duty.duty),
          city: values.city,
          state: values.state.value,
          startMonth: values.startMonth.value,
          startYear: values.startYear,
          endMonth: values.endMonth.value,
          endYear: values.endYear
        })
      );
    } else {
      dispatch(
        WorkHistoriesAction.requestUpdateWorkHistory({
          id: historyId,
          employerName: values.employerName,
          jobTitle: values.jobTitle,
          duties: values.duties.map((duty) => duty.duty),
          city: values.city,
          state: values.state.value,
          startMonth: values.startMonth.value,
          startYear: values.startYear,
          endMonth: values.endMonth.value,
          endYear: values.endYear
        })
      );
    }
  };

  const workHistory = workHistories.find(({ id }) => id === historyId);

  const initialValues = {
    employerName: workHistory ? workHistory.employerName : '',
    jobTitle: workHistory ? workHistory.jobTitle : '',
    duties: workHistory ? workHistory.duties.map((duty) => ({ id: uuid(), duty })) : [],
    city: workHistory ? workHistory.city : '',
    state: getStateOption(workHistory ? workHistory.state : states[0]),
    startMonth: getMonthOption(workHistory ? workHistory.startMonth : 1),
    startYear: workHistory ? workHistory.startYear : 2010,
    endMonth: getMonthOption(workHistory ? workHistory.endMonth : 12),
    endYear: workHistory ? workHistory.endYear : 2010,
    duty: ''
  };

  return (
    <div className={cls['employer-details']}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitForm}
      >
        {({ values, errors, touched, handleSubmit, handleChange, handleBlur }) => (
          <form onSubmit={handleSubmit}>
            <div className={cls['wrapper']}>
              <div className={cls['left-column']}>
                <FormGroupContainer className={cls['left-row1']}>
                  <FormGroup>
                    <TextField
                      label="EMPLOYER NAME"
                      placeholder="What is your employer's name?"
                      name="employerName"
                      errorMessage={
                        errors.employerName && touched.employerName && errors.employerName
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.employerName}
                    />
                  </FormGroup>
                  <FormGroup>
                    <TextField
                      label="JOB TITLE"
                      placeholder="What is the title of this position?"
                      name="jobTitle"
                      errorMessage={errors.jobTitle && touched.jobTitle && errors.jobTitle}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.jobTitle}
                    />
                  </FormGroup>
                </FormGroupContainer>
                <FormGroupContainer className={cls['left-row2']}>
                  <FormGroup style={{ flex: 1 }}>
                    <TextField
                      label="ESSENTIAL JOB DUTIES &amp; RESPONSIBILITIES"
                      placeholder="Enter job duty"
                      name="duty"
                      errorMessage={errors.duty && touched.duty && errors.duty}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.duty}
                    />
                    <Field name="duties" id="duties">
                      {({ field: { value }, form: { setFieldValue } }) => (
                        <>
                          <Button
                            clear
                            className={cls['duty-btn']}
                            onClick={(e) => {
                              e.preventDefault();
                              if (values.duty) {
                                setFieldValue('duties', [
                                  ...value,
                                  { id: uuid(), duty: values.duty }
                                ]);
                                setFieldValue('duty', '');
                              }
                            }}
                          >
                            <Icon mr>plus_rounded</Icon>Add Job Duty
                          </Button>
                          <div className={cls['items-list']}>
                            {value.map((data) => (
                              <div key={data.id} className={cls['item']}>
                                <div className={cls['item-label']}>{data.duty}</div>
                                <div
                                  className={cls['item-btn']}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setFieldValue(
                                      'duties',
                                      value.filter((v) => v.id !== data.id)
                                    );
                                  }}
                                >
                                  <Icon>close</Icon>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className={cls['select-error']}>
                            {errors.duties && touched.duties && errors.duties}
                          </div>
                        </>
                      )}
                    </Field>
                  </FormGroup>
                </FormGroupContainer>
              </div>
              <div className={cls['right-column']}>
                <FormGroupContainer className={cls['right-row1']}>
                  <div className={cls['label']}>Job Location</div>
                  <div className={cls['inputs']}>
                    <FormGroup>
                      <TextField
                        placeholder="City"
                        name="city"
                        errorMessage={errors.city && touched.city && errors.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.city}
                      />
                    </FormGroup>
                    <div style={{ minWidth: '120px', flexGrow: 1 }}>
                      <StatesSelect
                        showLabel={false}
                        values={values}
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                  </div>
                </FormGroupContainer>
                <FormGroupContainer className={cls['right-row2']}>
                  <div className={cls['label']}>Date Range</div>
                  <div className={cls['inputs']}>
                    <FormGroup>
                      <Field name="startMonth" id="startMonth">
                        {({ field: { value }, form: { setFieldValue } }) => {
                          return (
                            <>
                              <Select
                                placeholder="Select Month"
                                options={months.map((m, i) => ({ label: m, value: i + 1 }))}
                                activeOption={value}
                                onOptionClick={(option) => {
                                  setFieldValue('startMonth', option);
                                }}
                              />
                              <div className={cls['select-error']}>
                                {errors.startMonth && touched.startMonth && errors.startMonth}
                              </div>
                            </>
                          );
                        }}
                      </Field>
                    </FormGroup>
                    <FormGroup>
                      <TextField
                        placeholder="Start Year"
                        name="startYear"
                        type="number"
                        min={1970}
                        max={2050}
                        errorMessage={errors.startYear && touched.startYear && errors.startYear}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.startYear}
                      />
                    </FormGroup>
                    {!isCurrentUser && (
                      <>
                        <FormGroup>
                          <Field name="endMonth" id="endMonth">
                            {({ field: { value }, form: { setFieldValue } }) => {
                              return (
                                <>
                                  <Select
                                    placeholder="Select Month"
                                    options={months.map((m, i) => ({ label: m, value: i + 1 }))}
                                    activeOption={value}
                                    onOptionClick={(option) => {
                                      setFieldValue('endMonth', option);
                                    }}
                                  />
                                  <div className={cls['select-error']}>
                                    {errors.endMonth && touched.endMonth && errors.endMonth}
                                  </div>
                                </>
                              );
                            }}
                          </Field>
                        </FormGroup>
                        <FormGroup>
                          <TextField
                            placeholder="End Year"
                            name="endYear"
                            type="number"
                            min={1970}
                            max={2050}
                            errorMessage={errors.endYear && touched.endYear && errors.endYear}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.endYear}
                          />
                        </FormGroup>
                      </>
                    )}
                  </div>
                </FormGroupContainer>
                <div className={cls['buttons']}>
                  <Button type="submit" className={cls['update']}>
                    <Icon mr>{historyId === 'new-history' ? 'plus_rounded' : 'cloud_up'}</Icon>
                    {historyId === 'new-history' ? 'Create' : 'Update'}
                  </Button>
                  {historyId && historyId !== 'new-history' && (
                    <Button
                      className={cls['remove']}
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(
                          WorkHistoriesAction.requestDeleteWorkHistory(
                            historyId,
                            isCurrentUser ? 'current' : 'previous'
                          )
                        );
                      }}
                    >
                      <Icon mr>close</Icon>Remove
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default connect(mapStateToProps)(EmployerDetails);
