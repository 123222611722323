export const ModalTypes = {
  none: 'none',
  jsJobSearch: 'jsJobSearch',
  jsActiveJobs: 'jsActiveJobs',
  jsAdmin: 'jsAdmin',
  jsWorkHistoryWizard: 'jsWorkHistoryWizard',
  jsEducationWizard: 'jsEducationWizard',
  jsReferencesWizard: 'jsReferencesWizard',
  jsFullJobListing: 'jsFullJobListing',
  jpCandidateSearch: 'jpCandidateSearch',
  jpActiveJobs: 'jpActiveJobs',
  jpCreateJob: 'jpCreateJob',
  jpAdmin: 'jpAdmin',
  JPRequestInterview: 'JPRequestInterview',
  JPRequestResume: 'JPRequestResume',
  JPRequestHire: 'JPRequestHire',
  fullResume: 'fullResume'
};

export const SecondModalTypes = {
  none: 'none',
  jpCloseJobConfirm: 'jpCloseJobConfirm'
};
