import ActionUtility from '../../utilities/action-utility';
import WorkHistoriesEffect from './work-histories-effect';

export default class WorkHistoriesAction {
  static REQUEST_WORKHISTORIES = 'WorkHistoriesAction.REQUEST_WORKHISTORIES';
  static REQUEST_WORKHISTORIES_FINISHED = 'WorkHistoriesAction.REQUEST_WORKHISTORIES_FINISHED';
  static requestWorkHistories() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WorkHistoriesAction.REQUEST_WORKHISTORIES,
        WorkHistoriesEffect.requestWorkHistories
      );
    };
  }

  static REQUEST_CREATE_WORKHISTORY = 'WorkHistoriesAction.REQUEST_CREATE_WORKHISTORY';
  static REQUEST_CREATE_WORKHISTORY_FINISHED =
    'WorkHistoriesAction.REQUEST_CREATE_WORKHISTORY_FINISHED';
  static requestCreateWorkHistory(data) {
    return async (dispatch, action) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WorkHistoriesAction.REQUEST_CREATE_WORKHISTORY,
        WorkHistoriesEffect.requestCreateWorkHistory,
        data
      );
    };
  }

  static REQUEST_UPDATE_WORKHISTORY = 'WorkHistoriesAction.REQUEST_UPDATE_WORKHISTORY';
  static REQUEST_UPDATE_WORKHISTORY_FINISHED =
    'WorkHistoriesAction.REQUEST_UPDATE_WORKHISTORY_FINISHED';
  static requestUpdateWorkHistory(data) {
    return async (dispatch, action) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WorkHistoriesAction.REQUEST_UPDATE_WORKHISTORY,
        WorkHistoriesEffect.requestUpdateWorkHistory,
        data
      );
    };
  }

  static REQUEST_DELETE_WORKHISTORY = 'WorkHistoriesAction.REQUEST_DELETE_WORKHISTORY';
  static REQUEST_DELETE_WORKHISTORY_FINISHED =
    'WorkHistoriesAction.REQUEST_DELETE_WORKHISTORY_FINISHED';
  static requestDeleteWorkHistory(id, historyType) {
    return async (dispatch, action) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        WorkHistoriesAction.REQUEST_DELETE_WORKHISTORY,
        WorkHistoriesEffect.requestDeleteWorkHistory,
        id,
        historyType
      );
    };
  }
}
