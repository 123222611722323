import BaseReducer from '../../utilities/base-reducer';
import FiltersAction from './filters-action';

export default class FiltersReducer extends BaseReducer {
  initialState = {
    jobTypes: [],
    licenses: [],
    jobRoles: [],
    segments: [],
    educationLevels: []
  };

  [FiltersAction.REQUEST_JOBTYPES_FINISHED](state, action) {
    return { ...state, jobTypes: action.payload };
  }

  [FiltersAction.REQUEST_LICENSES_FINISHED](state, action) {
    return { ...state, licenses: action.payload };
  }

  [FiltersAction.REQUEST_JOBROLES_FINISHED](state, action) {
    return { ...state, jobRoles: action.payload };
  }

  [FiltersAction.REQUEST_SEGMENTS_FINISHED](state, action) {
    return { ...state, segments: action.payload };
  }

  [FiltersAction.REQUEST_EDUCATION_LEVELS_FINISHED](state, action) {
    return { ...state, educationLevels: action.payload };
  }
}
