import EffectUtility from '../../utilities/effect-utility';
import ApiRoutes from '../../shared/api-routes/api-routes';
import JobTypeResponseModel from './models/job-type-response-model';
import LicenseResponseModel from './models/license-response-model';
import JobRoleResponseModel from './models/job-role-response-model';
import SegmentResponseModel from './models/segment-response-model';
import EducationLevelResponseModel from './models/education-level-response-model';

export default class FiltersEffect {
  static async getJobTypes() {
    return EffectUtility.getToModel(JobTypeResponseModel, ApiRoutes.jobType);
  }

  static async getLicenses() {
    return EffectUtility.getToModel(LicenseResponseModel, ApiRoutes.license);
  }

  static async getJobRoles() {
    return EffectUtility.getToModel(JobRoleResponseModel, ApiRoutes.jobRole);
  }

  static async getSegments() {
    return EffectUtility.getToModel(SegmentResponseModel, ApiRoutes.segment);
  }

  static async getEducationLevels() {
    return EffectUtility.getToModel(EducationLevelResponseModel, ApiRoutes.educationLevel);
  }
}
