import { applyMiddleware, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { routerMiddleware } from 'connected-react-router';
import reduxFreeze from 'redux-freeze';
import rootReducer from './root-reducer';

export const history = createBrowserHistory();

export default (initialState) => {
  const middleware = [
    process.env.NODE_ENV === 'development' ? reduxFreeze : null,
    thunk,
    routerMiddleware(history)
  ].filter(Boolean);

  const store = createStore(
    rootReducer(history),
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
  );

  return store;
};
