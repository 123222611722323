import React, { Suspense, useEffect } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import PrivateRoute from './components/private-route/private-route';
import ModalContainer from './views/modal-container/modal-container';
import { routes } from './routes';
import FiltersAction from './store/filters/filters-action';
import AuthAction from './store/auth/auth-action';
import 'react-toastify/dist/ReactToastify.css';

const App = ({ history, dispatch }) => {
  const paths = Object.values(routes).map((route) => route.path);
  const path = history.location.pathname;

  useEffect(() => {
    if (paths.some((p) => p === path) || path === '/') {
      dispatch(FiltersAction.getJobTypes());
      dispatch(FiltersAction.getLicenses());
      dispatch(FiltersAction.getJobRoles());
      dispatch(FiltersAction.getSegments());
      dispatch(FiltersAction.getEducationLevels());
      dispatch(AuthAction.requestConfig());
    }
  }, []);

  return (
    <>
      <ConnectedRouter history={history}>
        <Suspense fallback={<div>Loading...</div>}>
          <ToastContainer autoClose={3000} />
          <Switch>
            <Redirect exact from="/" to="/signin" />
            {Object.values(routes).map((route, index) => {
              return route.auth ? (
                <PrivateRoute key={index} exact path={route.path} component={route.component} />
              ) : (
                <Route key={index} exact path={route.path} component={route.component} />
              );
            })}
            <Route component={routes.notFound.component} />
          </Switch>
        </Suspense>
      </ConnectedRouter>
      <ModalContainer />
    </>
  );
};

export default App;
