import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Button } from '@duik/it';
import Icon from '@duik/icon';
import Divider from '../../../../components/divider/divider';
import EducationDetails from './education-details/education-details';
import { selectUser } from '../../../../shared/selectors/auth/auth-selector';
import { selectRequesting } from '../../../../shared/selectors/requesting/requesting-selector';
import AuthAction from '../../../../store/auth/auth-action';
import EducationsAction from '../../../../store/educations/educations-action';
import ModalAction from '../../../../store/modal/modal-action';
import { ModalTypes } from '../../../../store/modal/models/modal-types';
import cls from './js-education-wizard-modal.module.scss';

const mapStateToProps = (state) => ({
  requesting: selectRequesting(state, [
    EducationsAction.REQUEST_CREATE_EDUCATION,
    EducationsAction.REQUEST_UPDATE_EDUCATION,
    EducationsAction.REQUEST_DELETE_EDUCATION
  ]),
  user: selectUser(state)
});

const JSEducationWizardModal = ({ onClose, user, requesting, dispatch }) => {
  const [educations, setEducations] = useState([]);

  useMemo(() => {
    if (user) {
      setEducations(user.educations);
    }
  }, [user]);

  useMemo(() => {
    if (!requesting) {
      dispatch(AuthAction.requestUser());
    }
  }, [requesting]);

  return (
    <div className={cls['js-education-wizard-modal']}>
      <div className={cls['header']}>
        <h2 className={cls['header-title']}>Add Education</h2>
        <div className={cls['header-right']}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              onClose();
            }}
            className={cls['close-icon']}
            square
          >
            <Icon>close</Icon>
          </Button>
        </div>
      </div>
      <Divider />
      <div className={cls['content']}>
        {educations.map((education, index) => (
          <EducationDetails educationId={education} key={index} />
        ))}
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Button
            className={cls['add']}
            onClick={(e) => {
              e.preventDefault();
              setEducations([...educations, 'new-education']);
            }}
          >
            <Icon mr>plus_rounded</Icon>Add Education
          </Button>
        </div>
      </div>
      <Divider />
      <div className={cls['footer']}>
        <Button
          className={cls['prev']}
          onClick={(e) => {
            e.preventDefault();
            dispatch(
              ModalAction.updateModalState({ isOpen: true, type: ModalTypes.jsWorkHistoryWizard })
            );
          }}
        >
          <Icon mr>arrow_left</Icon>Back
        </Button>
        <Button
          className={cls['next']}
          onClick={(e) => {
            e.preventDefault();
            dispatch(
              ModalAction.updateModalState({ isOpen: true, type: ModalTypes.jsReferencesWizard })
            );
          }}
        >
          <Icon mr>arrow_right</Icon>Next
        </Button>
        <Button
          className={cls['cancel']}
          onClick={(e) => {
            e.preventDefault();
            dispatch(ModalAction.updateModalState({ isOpen: false }));
          }}
        >
          <Icon mr>close</Icon>Cancel
        </Button>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(JSEducationWizardModal);
