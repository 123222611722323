import ActionUtility from '../../utilities/action-utility';
import ProvidersEffect from './providers-effect';

export default class ProvidersAction {
  static REQUEST_PROVIDERS = 'ProvidersAction.REQUEST_PROVIDERS';
  static REQUEST_PROVIDERS_FINISHED = 'ProvidersAction.REQUEST_PROVIDERS_FINISHED';
  static requestProviders() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ProvidersAction.REQUEST_PROVIDERS,
        ProvidersEffect.requestProviders
      );
    };
  }
}
