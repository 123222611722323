import React from 'react';
import Select from 'react-select';
import { FormGroup } from '@duik/it';
import { Field } from 'formik';
import { states } from '../../shared/sample-data';

const stateOptions = states.map((state) => ({
  value: state,
  label: state
}));

const StatesSelect = ({ values, errors, touched, showLabel = true, disabled }) => {
  return (
    <Field name="state">
      {({ form: { setFieldValue, setFieldTouched } }) => {
        return (
          <>
            <FormGroup>
              {showLabel && <label>State</label>}
              <Select
                label="asdf"
                className="react-select"
                classNamePrefix="react-select"
                options={stateOptions}
                value={values.state}
                onChange={(option) => {
                  setFieldValue('state', option);
                }}
                onBlur={() => setFieldTouched('state', true)}
                styles={{
                  control: (provided) =>
                    !!errors.state && !!touched.state
                      ? {
                          ...provided,
                          borderColor: '#e6492d !important',
                          height: '32px',
                          minHeight: '32px'
                        }
                      : { ...provided, height: '32px', minHeight: '32px' },
                  valueContainer: (provided) => ({
                    ...provided,
                    height: '32px'
                  }),
                  indicatorsContainer: (provided) => ({
                    ...provided,
                    height: '32px'
                  })
                }}
                isDisabled={disabled}
              />
              {errors.state && touched.state && (
                <div className="invalid-feedback">{errors.state.value}</div>
              )}
            </FormGroup>
          </>
        );
      }}
    </Field>
  );
};

export default StatesSelect;
