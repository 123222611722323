import ApiRoutes from '../../shared/api-routes/api-routes';
import EffectUtility from '../../utilities/effect-utility';
import JobResponseModel from './models/job-response-model';
import DeleteResponseModel from '../../shared/models/delete-response-model';

export default class JobPostEffect {
  static async requestJobs() {
    return EffectUtility.getToModel(JobResponseModel, ApiRoutes.job);
  }

  static async requestAddJobPost(data) {
    return EffectUtility.postToModel(JobResponseModel, `${ApiRoutes.job}/add`, data);
  }

  static async requestEditJobPost(data) {
    return EffectUtility.postToModel(JobResponseModel, `${ApiRoutes.job}/edit`, data);
  }

  static async requestDeleteJobPost(id) {
    return EffectUtility.deleteToModel(DeleteResponseModel, `${ApiRoutes.job}/${id}`);
  }

  static async requestInvite(data) {
    return EffectUtility.postToModel(JobResponseModel, `${ApiRoutes.job}/invite`, data);
  }

  static async requestResume(data) {
    return EffectUtility.postToModel(JobResponseModel, `${ApiRoutes.job}/resume`, data);
  }

  static async requestApply(data) {
    return EffectUtility.postToModel(JobResponseModel, `${ApiRoutes.job}/apply`, data);
  }

  static async requestDecline(data) {
    return EffectUtility.postToModel(JobResponseModel, `${ApiRoutes.job}/decline`, data);
  }

  static async requestReject(data) {
    return EffectUtility.postToModel(JobResponseModel, `${ApiRoutes.job}/reject`, data);
  }

  static async requestHire(data) {
    return EffectUtility.postToModel(JobResponseModel, `${ApiRoutes.job}/hire`, data);
  }

  static async requestCancel(data) {
    return EffectUtility.postToModel(JobResponseModel, `${ApiRoutes.job}/cancel`, data);
  }
}
