import ActionUtility from '../../utilities/action-utility';
import JobsEffect from './jobs-effect';

export default class JobsAction {
  static REQUEST_JOBS = 'JobsAction.REQUEST_JOBS';
  static REQUEST_JOBS_FINISHED = 'JobsAction.REQUEST_JOBS_FINISHED';
  static requestJobs() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        JobsAction.REQUEST_JOBS,
        JobsEffect.requestJobs
      );
    };
  }

  static REQUEST_ADD_JOBPOST = 'JobsAction.REQUEST_ADD_JOBPOST';
  static REQUEST_ADD_JOBPOST_FINISHED = 'JobsAction.REQUEST_ADD_JOBPOST_FINISHED';
  static requestAddJobPost(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        JobsAction.REQUEST_ADD_JOBPOST,
        JobsEffect.requestAddJobPost,
        data
      );
    };
  }

  static REQUEST_EDIT_JOBPOST = 'JobsAction.REQUEST_EDIT_JOBPOST';
  static REQUEST_EDIT_JOBPOST_FINISHED = 'JobsAction.REQUEST_EDIT_JOBPOST_FINISHED';
  static requestEditJobPost(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        JobsAction.REQUEST_EDIT_JOBPOST,
        JobsEffect.requestEditJobPost,
        data
      );
    };
  }

  static REQUEST_DELETE_JOBPOST = 'JobsAction.REQUEST_DELETE_JOBPOST';
  static REQUEST_DELETE_JOBPOST_FINISHED = 'JobsAction.REQUEST_DELETE_JOBPOST_FINISHED';
  static requestDeleteJobPost(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        JobsAction.REQUEST_DELETE_JOBPOST,
        JobsEffect.requestDeleteJobPost,
        id
      );
    };
  }

  static REQUEST_INVITE = 'JobsAction.REQUEST_INVITE';
  static REQUEST_INVITE_FINISHED = 'JobsAction.REQUEST_INVITE_FINISHED';
  static requestInvite(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        JobsAction.REQUEST_INVITE,
        JobsEffect.requestInvite,
        data
      );
    };
  }

  static REQUEST_RESUME = 'JobsAction.REQUEST_RESUME';
  static REQUEST_RESUME_FINISHED = 'JobsAction.REQUEST_RESUME_FINISHED';
  static requestResume(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        JobsAction.REQUEST_RESUME,
        JobsEffect.requestResume,
        data
      );
    };
  }

  static REQUEST_APPLY = 'JobsAction.REQUEST_APPLY';
  static REQUEST_APPLY_FINISHED = 'JobsAction.REQUEST_APPLY_FINISHED';
  static requestApply(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        JobsAction.REQUEST_APPLY,
        JobsEffect.requestApply,
        data
      );
    };
  }

  static REQUEST_DECLINE = 'JobsAction.REQUEST_DECLINE';
  static REQUEST_DECLINE_FINISHED = 'JobsAction.REQUEST_DECLINE_FINISHED';
  static requestDecline(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        JobsAction.REQUEST_DECLINE,
        JobsEffect.requestDecline,
        data
      );
    };
  }

  static REQUEST_REJECT = 'JobsAction.REQUEST_REJECT';
  static REQUEST_REJECT_FINISHED = 'JobsAction.REQUEST_REJECT_FINISHED';
  static requestReject(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        JobsAction.REQUEST_REJECT,
        JobsEffect.requestReject,
        data
      );
    };
  }

  static REQUEST_HIRE = 'JobsAction.REQUEST_HIRE';
  static REQUEST_HIRE_FINISHED = 'JobsAction.REQUEST_HIRE_FINISHED';
  static requestHire(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        JobsAction.REQUEST_HIRE,
        JobsEffect.requestHire,
        data
      );
    };
  }

  static REQUEST_CANCEL = 'JobsAction.REQUEST_CANCEL';
  static REQUEST_CANCEL_FINISHED = 'JobsAction.REQUEST_CANCEL_FINISHED';
  static requestCancel(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        JobsAction.REQUEST_CANCEL,
        JobsEffect.requestCancel,
        data
      );
    };
  }
}
