import { BaseModel } from 'sjs-base-model';

/*
    // Returned Api Data Sample
    {
      
    }
 */
export default class WorkHistoryResponseModel extends BaseModel {
  id = '';
  employerName = '';
  jobTitle = '';
  duties = [];
  city = '';
  state = '';
  startMonth = 0;
  startYear = 0;
  endMonth = 0;
  endYear = 0;

  constructor(data) {
    super();

    this.update(data);
  }

  update(data) {
    this.id = data._id;

    super.update(data);
  }
}
