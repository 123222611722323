import EffectUtility from '../../utilities/effect-utility';
import ApiRoutes from '../../shared/api-routes/api-routes';
import SigninResponseModel from './models/signin-response-model';
import SignupResponseModel from './models/signup-response-model';
import UserResponseModel from './models/user-response-model';
import UpdateAvatarResponseModel from './models/update-avatar-response-model';
import ActivateUserResponseModel from './models/activate-user-response-model';
import ConfigResponseModel from './models/config-response-model';
import SuccessResponseModel from '../../shared/models/success-response-model';

export default class AuthEffect {
  static async requestSignin(email, password) {
    return EffectUtility.postToModel(SigninResponseModel, ApiRoutes.signin, { email, password });
  }

  static async requestSeekerSignup(data) {
    return EffectUtility.postToModel(SignupResponseModel, ApiRoutes.signupSeeker, data);
  }

  static async requestProviderSignup(data) {
    return EffectUtility.postToModel(SignupResponseModel, ApiRoutes.signupProvider, data);
  }

  static async requestActivateUser(data) {
    return EffectUtility.postToModel(ActivateUserResponseModel, ApiRoutes.confirmEmail, data);
  }

  static async requestResetPassword(data) {
    return EffectUtility.postToModel(SuccessResponseModel, ApiRoutes.resetPassword, data);
  }

  static async requestChangePassword(data) {
    return EffectUtility.postToModel(SuccessResponseModel, ApiRoutes.changePassword, data);
  }

  static async requestUser() {
    return EffectUtility.getToModel(UserResponseModel, ApiRoutes.user);
  }

  static async requestConfig() {
    return EffectUtility.getToModel(ConfigResponseModel, ApiRoutes.config);
  }

  static async requestUpdateSeeker(data) {
    return EffectUtility.postToModel(UserResponseModel, `${ApiRoutes.user}/edit-seeker`, data);
  }

  static async requestUpdateProvider(data) {
    return EffectUtility.postToModel(UserResponseModel, `${ApiRoutes.user}/edit-provider`, data);
  }

  static async requestUpdateAvatar(file) {
    return EffectUtility.postToModel(
      UpdateAvatarResponseModel,
      `${ApiRoutes.user}/edit-avatar`,
      file
    );
  }
}
