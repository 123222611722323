import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { months } from '../../../../../shared/sample-data';
import { workHistoryStyles } from './styles';

const WorkHistoryTemplate = ({ data, current }) => {
  return (
    <View style={workHistoryStyles.content}>
      <View style={workHistoryStyles.column1}>
        <Text style={workHistoryStyles.column1Text}>EMPLOYER NAME: {data.employerName}</Text>
        <Text style={workHistoryStyles.column1Text}>JOB TITLE: {data.jobTitle}</Text>
        <Text style={workHistoryStyles.column1Text}>
          JOB LOCATION: {data.city + ', ' + data.state}
        </Text>
      </View>
      <View style={workHistoryStyles.column2}>
        <Text style={workHistoryStyles.column2Title}>
          ESSENTIAL JOB DUTIES &amp; RESPONSIBILITIES
        </Text>
        {data.duties.map((duty, index) => (
          <Text style={workHistoryStyles.column2Text} key={index}>
            {duty}
          </Text>
        ))}
      </View>
      <View style={workHistoryStyles.column3}>
        <Text style={workHistoryStyles.column3Title}>DATE RANGE</Text>
        <Text style={workHistoryStyles.column3Text}>Start Date</Text>
        <Text style={workHistoryStyles.column3Text}>
          {months[data.startMonth - 1] + ' ' + data.startYear}
        </Text>
        {current === true && (
          <View>
            <Text style={workHistoryStyles.column3Text}>End Date</Text>
            <Text style={workHistoryStyles.column3Text}>
              {months[data.endMonth - 1] + ' ' + data.endYear}
            </Text>
          </View>
        )}
      </View>
    </View>
  );
};

export default WorkHistoryTemplate;
