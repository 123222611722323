import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { months } from '../../../../../shared/sample-data';
import { educationStyles } from './styles';

const EducationTemplate = ({ data }) => {
  return (
    <View style={educationStyles.content}>
      <View style={educationStyles.column1}>
        <Text style={educationStyles.column1Text}>NAME: {data.name}</Text>
        <Text style={educationStyles.column1Text}>LOCATION: {data.city + ', ' + data.state}</Text>
        <Text style={educationStyles.column1Text}>DEGREE OBTAINED: {data.degree}</Text>
      </View>
      <View style={educationStyles.column2}>
        <Text style={educationStyles.column2Title}>DEGREE PROGRAM AND CONCENTRATION</Text>
        {data.accomplishments.map((accomplishment, index) => (
          <Text style={educationStyles.column2Text} key={index}>
            {accomplishment}
          </Text>
        ))}
      </View>
      <View style={educationStyles.column3}>
        <Text style={educationStyles.column3Title}>DATE RANGE</Text>
        <Text style={educationStyles.column3Text}>Start Date</Text>
        <Text style={educationStyles.column3Text}>
          {months[data.startMonth - 1] + ' ' + data.startYear}
        </Text>
        <Text style={educationStyles.column3Text}>End Date</Text>
        <Text style={educationStyles.column3Text}>
          {months[data.endMonth - 1] + ' ' + data.endYear}
        </Text>
      </View>
    </View>
  );
};

export default EducationTemplate;
