import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, TextField, TextArea, Select } from '@duik/it';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { default as AvatarEdit } from 'react-avatar-edit';
import Icon from '@duik/icon';
import Divider from '../../../../components/divider/divider';
import Slider, { SliderTypes } from '../../../../components/slider/slider';
import FormCheckbox from '../../../../components/form-checkbox/form-checkbox';
import { selectFilters } from '../../../../shared/selectors/filters/filters-selector';
import { selectUser } from '../../../../shared/selectors/auth/auth-selector';
import AuthAction from '../../../../store/auth/auth-action';
import ModalAction from '../../../../store/modal/modal-action';
import { ModalTypes } from '../../../../store/modal/models/modal-types';
import { selectRequesting } from '../../../../shared/selectors/requesting/requesting-selector';
import { getStateOption, states } from '../../../../shared/sample-data';
import StatesSelect from '../../../../components/states-select/states-select';
import cls from './js-admin-modal.module.scss';

const mapStateToProps = (state) => ({
  requestingUpdateAvatar: selectRequesting(state, [AuthAction.REQUEST_UPDATE_AVATAR]),
  requestingUpdateSeeker: selectRequesting(state, [AuthAction.REQUEST_UPDATE_SEEKER]),
  user: selectUser(state),
  filters: selectFilters(state)
});

const JSAdminModal = ({
  onClose,
  user,
  filters,
  requestingUpdateAvatar,
  requestingUpdateSeeker,
  dispatch
}) => {
  const [image, setImage] = useState({ preview: null, src: null });

  const jobTypeOptions = filters.jobTypes.map((t) => ({ id: t.id, name: t.name }));
  const licenseOptions = filters.licenses.map((t) => ({ id: t.id, name: t.name }));
  const jobRoleOptions = filters.jobRoles.map((t) => ({ id: t.id, name: t.name }));
  const segmentOptions = filters.segments.map((t) => ({ id: t.id, name: t.name }));
  const educationLevelOptions = filters.educationLevels.map((t) => ({
    value: t.id,
    label: t.name
  }));

  useEffect(() => {
    if (user && !user.openedProfile) {
      dispatch(AuthAction.requestUpdateSeeker({ ...user, openedProfile: true }));
    }
  }, [dispatch, user]);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('This field is required'),
    lastName: Yup.string().required('This field is required'),
    email: Yup.string().required('This field is required').email('Invalid Email'),
    phoneNumber: Yup.string().required('This field is required'),
    address: Yup.string().required('This field is required'),
    country: Yup.string().required('This field is required'),
    state: Yup.object({
      value: Yup.string().required('This field is required')
    }),
    city: Yup.string().required('This field is required'),
    zip: Yup.string().required('This field is required'),
    minSalaryReq: Yup.number()
      .required('This field is required')
      .test('MinMax Test', '', (value) => value > 10000 && value < 350000),
    jobRoles: Yup.array().min(1, 'This field is required'),
    educationLevel: Yup.object().required('This field is required'),
    experience: Yup.number()
      .required('This field is required')
      .test('MinMax Test', '', (value) => value > 1 && value < 50),
    jobTypes: Yup.array().min(1, 'This field is required'),
    licenses: Yup.array().min(1, 'This field is required'),
    segments: Yup.array().min(1, 'This field is required')
  });

  const submitForm = (values) => {
    let request = {
      id: user.id,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      address: values.address,
      addressLine: values.addressLine,
      country: values.country,
      state: values.state.value,
      city: values.city,
      zip: values.zip,
      maxCommute: values.maxCommute,
      minSalaryReq: values.minSalaryReq,
      jobRoles: values.jobRoles,
      headline: values.headline,
      educationLevel: values.educationLevel.value,
      experience: values.experience,
      jobTypes: values.jobTypes,
      licenses: values.licenses,
      segments: values.segments
    };

    dispatch(AuthAction.requestUpdateSeeker(request));
  };

  const initialValues = {
    firstName: user ? user.firstName : '',
    lastName: user ? user.lastName : '',
    email: user ? user.email : '',
    phoneNumber: user ? user.phoneNumber : '',
    address: user ? user.address : '',
    addressLine: user ? user.addressLine : '',
    country: user ? user.country : '',
    state: getStateOption(user ? user.state : states[0]),
    city: user ? user.city : '',
    zip: user ? user.zip : '',
    maxCommute: user ? user.maxCommute : 25,
    minSalaryReq: user ? user.minSalaryReq : 10000,
    jobRoles: user ? user.jobRoles : [],
    headline: user ? user.headline : '',
    educationLevel: user
      ? educationLevelOptions.find((el) => el.value === user.educationLevel)
      : undefined,
    experience: user ? user.experience : 1,
    jobTypes: user ? user.jobTypes : [],
    licenses: user ? user.licenses : [],
    segments: user ? user.segments : []
  };

  const onCrop = (preview) => setImage((p) => ({ ...p, preview }));

  const onCloseAvatarEdit = () => {
    setImage({ preview: null, src: null });
  };

  const uploadAvatar = () => {
    if (image.preview) {
      dispatch(AuthAction.requestUpdateAvatar({ file: image.preview }));
    }
  };

  return (
    <div className={cls['js-admin-modal']}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitForm}
      >
        {({ values, errors, touched, handleSubmit, handleChange, handleBlur }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className={cls['header']}>
                <h2 className={cls['header-title']}>Candidate Profile</h2>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    onClose();
                  }}
                  className={cls['header-icon']}
                  square
                >
                  <Icon>close</Icon>
                </Button>
              </div>
              <Divider />
              <div className={cls['content']}>
                <div className={cls['header-inputs']}>
                  <div className={cls['group']}>
                    <TextField
                      label="FIRST NAME"
                      name="firstName"
                      errorMessage={errors.firstName && touched.firstName && errors.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                    />
                    <TextField
                      label="LAST NAME"
                      name="lastName"
                      errorMessage={errors.lastName && touched.lastName && errors.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                    />
                  </div>
                  <div className={cls['group']}>
                    <TextField
                      label="Email"
                      name="email"
                      errorMessage={errors.email && touched.email && errors.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <TextField
                      label="Phone Number"
                      name="phoneNumber"
                      errorMessage={errors.phoneNumber && touched.phoneNumber && errors.phoneNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phoneNumber}
                    />
                  </div>
                </div>
                <div className={cls['title-section']}>
                  <div className={cls['titles']}>
                    <div className={cls['title']}>
                      <TextField
                        label="Address"
                        name="address"
                        errorMessage={errors.address && touched.address && errors.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.address}
                      />
                    </div>
                    <div className={cls['title']}>
                      <TextField
                        label="COUNTRY"
                        name="country"
                        errorMessage={errors.country && touched.country && errors.country}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.country}
                      />
                      <TextField
                        label="ADDRESS LINE 2"
                        name="addressLine"
                        errorMessage={
                          errors.addressLine && touched.addressLine && errors.addressLine
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.addressLine}
                      />
                    </div>
                    <div className={cls['title']}>
                      <TextField
                        label="CITY"
                        name="city"
                        errorMessage={errors.city && touched.city && errors.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.city}
                      />
                    </div>
                    <div className={cls['title']}>
                      <StatesSelect values={values} errors={errors} touched={touched} />
                      <TextField
                        label="ZIP CODE"
                        name="zip"
                        type="number"
                        errorMessage={errors.zip && touched.zip && errors.zip}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.zip}
                      />
                    </div>
                  </div>
                  <div className={cls['avatar']}>
                    {requestingUpdateAvatar ? (
                      <div style={{ textAlign: 'center' }}>Updating...</div>
                    ) : (
                      <AvatarEdit
                        width={300}
                        height={200}
                        minCropRadius={50}
                        onCrop={onCrop}
                        onClose={onCloseAvatarEdit}
                        src={image.src}
                      />
                    )}
                    <Button
                      className={cls['update']}
                      onClick={(e) => {
                        e.preventDefault();
                        uploadAvatar();
                      }}
                      disabled={requestingUpdateAvatar}
                    >
                      <Icon mr>cloud_up</Icon>Save
                    </Button>
                  </div>
                </div>
                <div className={cls['mgmt-section']}>
                  <div className={cls['mgmt-inputs']}>
                    <Field name="maxCommute" id="maxCommute">
                      {({ field: { value }, form: { setFieldValue } }) => {
                        return (
                          <Slider
                            title="Maximum Commute"
                            type={SliderTypes.miles}
                            value={value}
                            min={1}
                            max={100}
                            onChange={(val) => {
                              setFieldValue('maxCommute', Number(val));
                            }}
                          />
                        );
                      }}
                    </Field>
                    <TextField
                      label="Mimimum Salary Requirement(USD)"
                      name="minSalaryReq"
                      type="number"
                      min={10000}
                      max={350000}
                      errorMessage={
                        errors.minSalaryReq && touched.minSalaryReq && errors.minSalaryReq
                      }
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      value={values.minSalaryReq}
                    />
                  </div>
                  <div className={cls['mgmt-roles']}>
                    <div className={cls['title']}>Job Role</div>
                    <Field name="jobRoles" id="jobRoles">
                      {({ field: { value }, form: { setFieldValue } }) => (
                        <>
                          {jobRoleOptions.map((data, index) => (
                            <FormCheckbox
                              key={index}
                              data={data}
                              formValue={value}
                              setFieldValue={setFieldValue}
                              fieldName="jobRoles"
                            />
                          ))}
                          <div className={cls['select-error']}>
                            {errors.jobRoles && touched.jobRoles && errors.jobRoles}
                          </div>
                        </>
                      )}
                    </Field>
                  </div>
                </div>
                <div className={cls['desc-section']}>
                  <div className={cls['desc-title']}>DESCRIPTION / OBJECTIVE</div>
                  <TextArea
                    name="headline"
                    errorMessage={errors.headline && touched.headline && errors.headline}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.headline}
                  />
                </div>
                <div className={cls['years-section']}>
                  <div className={cls['years']}>
                    <Field name="educationLevel" id="educationLevel">
                      {({ field: { value }, form: { setFieldValue } }) => {
                        return (
                          <>
                            <Select
                              label="Education Level"
                              placeholder="Choose Education Level"
                              options={educationLevelOptions}
                              activeOption={value}
                              onOptionClick={(option, name) => {
                                setFieldValue('educationLevel', option);
                              }}
                            />
                            <div className={cls['select-error']}>
                              {errors.educationLevel &&
                                touched.educationLevel &&
                                errors.educationLevel}
                            </div>
                          </>
                        );
                      }}
                    </Field>
                  </div>
                  <div className={cls['years']}>
                    <TextField
                      label="EXPERIENCE(years)"
                      name="experience"
                      type="number"
                      min={1}
                      max={50}
                      errorMessage={errors.experience && touched.experience && errors.experience}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      value={values.experience}
                    />
                  </div>
                </div>
                <div className={cls['props-section']}>
                  <div className={cls['column']}>
                    <div className={cls['title']}>Job Type</div>
                    <Field name="jobTypes" id="jobTypes">
                      {({ field: { value }, form: { setFieldValue } }) => (
                        <>
                          {jobTypeOptions.map((data, index) => (
                            <FormCheckbox
                              key={index}
                              data={data}
                              formValue={value}
                              setFieldValue={setFieldValue}
                              fieldName="jobTypes"
                            />
                          ))}
                          <div className={cls['select-error']}>
                            {errors.jobTypes && touched.jobTypes && errors.jobTypes}
                          </div>
                        </>
                      )}
                    </Field>
                  </div>
                  <div className={cls['column']}>
                    <div className={cls['title']}>Insurance Segment</div>
                    <Field name="segments" id="segments">
                      {({ field: { value }, form: { setFieldValue } }) => (
                        <>
                          {segmentOptions.map((data, index) => (
                            <FormCheckbox
                              key={index}
                              data={data}
                              formValue={value}
                              setFieldValue={setFieldValue}
                              fieldName="segments"
                            />
                          ))}
                          <div className={cls['select-error']}>
                            {errors.segments && touched.segments && errors.segments}
                          </div>
                        </>
                      )}
                    </Field>
                  </div>
                  <div className={cls['column']}>
                    <div className={cls['title']}>Your Current Licensing</div>
                    <Field name="licenses" id="licenses">
                      {({ field: { value }, form: { setFieldValue } }) => (
                        <>
                          {licenseOptions.map((data, index) => (
                            <FormCheckbox
                              key={index}
                              data={data}
                              formValue={value}
                              setFieldValue={setFieldValue}
                              fieldName="licenses"
                            />
                          ))}
                          <div className={cls['select-error']}>
                            {errors.licenses && touched.licenses && errors.licenses}
                          </div>
                        </>
                      )}
                    </Field>
                  </div>
                </div>
              </div>
              <div className={cls['footer']}>
                <Button type="submit" className={cls['update']} loading={requestingUpdateSeeker}>
                  <Icon mr>cloud_up</Icon>Update Profile
                </Button>
                <Button
                  className={cls['builder']}
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(
                      ModalAction.updateModalState({
                        isOpen: true,
                        type: ModalTypes.jsWorkHistoryWizard
                      })
                    );
                  }}
                >
                  <Icon mr>trending_up</Icon>Build Resume
                </Button>
                <Button
                  className={cls['view']}
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(
                      ModalAction.updateModalState({
                        isOpen: true,
                        type: ModalTypes.fullResume
                      })
                    );
                  }}
                >
                  <Icon mr>profile_card</Icon>View Full Resume
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default connect(mapStateToProps)(JSAdminModal);
