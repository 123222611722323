import ActionUtility from '../../utilities/action-utility';
import FiltersEffect from './filters-effect';

export default class FiltersAction {
  static REQUEST_JOBTYPES = 'FiltersAction.REQUEST_JOBTYPES';
  static REQUEST_JOBTYPES_FINISHED = 'FiltersAction.REQUEST_JOBTYPES_FINISHED';
  static getJobTypes() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FiltersAction.REQUEST_JOBTYPES,
        FiltersEffect.getJobTypes
      );
    };
  }

  static REQUEST_LICENSES = 'FiltersAction.REQUEST_LICENSES';
  static REQUEST_LICENSES_FINISHED = 'FiltersAction.REQUEST_LICENSES_FINISHED';
  static getLicenses() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FiltersAction.REQUEST_LICENSES,
        FiltersEffect.getLicenses
      );
    };
  }

  static REQUEST_JOBROLES = 'FiltersAction.REQUEST_JOBROLES';
  static REQUEST_JOBROLES_FINISHED = 'FiltersAction.REQUEST_JOBROLES_FINISHED';
  static getJobRoles() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FiltersAction.REQUEST_JOBROLES,
        FiltersEffect.getJobRoles
      );
    };
  }

  static REQUEST_SEGMENTS = 'FiltersAction.REQUEST_SEGMENTS';
  static REQUEST_SEGMENTS_FINISHED = 'FiltersAction.REQUEST_SEGMENTS_FINISHED';
  static getSegments() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FiltersAction.REQUEST_SEGMENTS,
        FiltersEffect.getSegments
      );
    };
  }

  static REQUEST_EDUCATION_LEVELS = 'FiltersAction.REQUEST_EDUCATION_LEVELS';
  static REQUEST_EDUCATION_LEVELS_FINISHED = 'FiltersAction.REQUEST_EDUCATION_LEVELS_FINISHED';
  static getEducationLevels() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        FiltersAction.REQUEST_EDUCATION_LEVELS,
        FiltersEffect.getEducationLevels
      );
    };
  }
}
