import BaseReducer from '../../utilities/base-reducer';
import SidebarAction from './sidebar-action';

export default class SidebarReducer extends BaseReducer {
  initialState = {
    filters: null
  };

  [SidebarAction.UPDATE_FILTERS](state, action) {
    return { ...state, filters: action.payload };
  }
}
