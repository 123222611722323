import { BaseModel } from 'sjs-base-model';

/*
    // Returned Api Data Sample
    {
      
    }
 */
export default class DeleteResponseModel extends BaseModel {
  id = '';

  constructor(data) {
    super();

    this.update(data);
  }

  update(data) {
    super.update(data);
  }
}
