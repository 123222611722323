import { BaseModel } from 'sjs-base-model';

/*
    // Returned Api Data Sample
    {
      id: "5f2092422a646daefb290142"
      name: "Account Executive / Specialist"
      order: 1
    }
 */
export default class JobResponseModel extends BaseModel {
  id = '';
  name = '';
  address = '';
  addressLine = '';
  country = '';
  state = '';
  city = '';
  zip = 0;
  location = {};
  shortDesc = '';
  longDesc = '';
  jobTypes = [];
  management = '';
  salaryRange = {};
  hourlyRateRange = {};
  displayToCandidate = true;
  isHourlyRate = false;
  reqExp = 1;
  maxCommute = 25;
  reqEducation = '';
  segments = [];
  licenses = [];
  jobDuties = [];
  skills = [];
  requirements = [];
  additionalNotes = '';
  opened = true;
  jobOwner = '';
  appliedUsers = [];
  reusable = false;
  createdAt = '';

  constructor(data) {
    super();

    this.update(data);
  }

  update(data) {
    super.update(data);

    this.id = data._id;
    // this.role = data.ROLE;
  }
}
