import { lazy } from 'react';

const SignInPage = lazy(() => import('./views/signin-page/signin-page'));
const SignUpPage = lazy(() => import('./views/signup-page/signup-page'));
const JSRegisterPage = lazy(() => import('./views/js-register-page/js-register-page'));
const JPRegisterPage = lazy(() => import('./views/jp-register-page/jp-register-page'));
const OAuthRegisterpage = lazy(() => import('./views/oauth-register-page/oauth-register-page'));
const ActivateUserPage = lazy(() => import('./views/activate-user-page/activate-user-page'));
const ResetPasswordPage = lazy(() => import('./views/reset-password-page/reset-password-page'));
const ChangePasswordPage = lazy(() => import('./views/change-password-page/change-password-page'));
const JSJobSearchPage = lazy(() => import('./views/js-job-search-page/js-job-search-page'));
const JSActiveJobsPage = lazy(() => import('./views/js-active-jobs-page/js-active-jobs-page'));
const JSArchivedJobsPage = lazy(() =>
  import('./views/js-archived-jobs-page/js-archived-jobs-page')
);
const JPCandidateSearchPage = lazy(() =>
  import('./views/jp-candidate-search-page/jp-candidate-search-page')
);
const JPActiveJobsPage = lazy(() => import('./views/jp-active-jobs-page/jp-active-jobs-page'));
const JPArchivedJobsPage = lazy(() =>
  import('./views/jp-archived-jobs-page/jp-archived-jobs-page')
);
const JPTemplatesPage = lazy(() => import('./views/jp-templates-page/jp-templates-page'));
const NotFoundPage = lazy(() => import('./views/not-found-page/not-found-page'));

export const routes = {
  signin: {
    path: '/signin',
    component: SignInPage
  },
  signup: {
    path: '/signup',
    component: SignUpPage
  },
  jsRegister: {
    path: '/js-register',
    component: JSRegisterPage
  },
  jpRegister: {
    path: '/jp-register',
    component: JPRegisterPage
  },
  oauthRegister: {
    path: '/oauth-register',
    component: OAuthRegisterpage
  },
  activateUser: {
    path: '/activate-user/:token',
    component: ActivateUserPage
  },
  resetPassword: {
    path: '/reset-password',
    component: ResetPasswordPage
  },
  changePassword: {
    path: '/change-password/:token',
    component: ChangePasswordPage
  },
  jsJobSearch: {
    path: '/js-job-search',
    component: JSJobSearchPage,
    auth: true
  },
  jsActiveJobs: {
    path: '/js-active-jobs',
    component: JSActiveJobsPage,
    auth: true
  },
  jsArchivedJobs: {
    path: '/js-archived-jobs',
    component: JSArchivedJobsPage,
    auth: true
  },
  jpCandidateSearch: {
    path: '/jp-candidate-search',
    component: JPCandidateSearchPage,
    auth: true
  },
  jpActiveJobs: {
    path: '/jp-active-jobs',
    component: JPActiveJobsPage,
    auth: true
  },
  jpArchivedJobs: {
    path: '/jp-archived-jobs',
    component: JPArchivedJobsPage,
    auth: true
  },
  jpTemplates: {
    path: '/jp-templates',
    component: JPTemplatesPage,
    auth: true
  },
  notFound: {
    component: NotFoundPage
  }
};
