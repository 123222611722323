import { toast } from 'react-toastify';
import BaseReducer from '../../utilities/base-reducer';
import ReferencesAction from './references-action';

export default class ReferencesReducer extends BaseReducer {
  initialState = {
    references: []
  };

  [ReferencesAction.REQUEST_REFERENCES_FINISHED](state, action) {
    return { ...state, references: action.payload };
  }

  [ReferencesAction.REQUEST_CREATE_REFERENCE_FINISHED](state, action) {
    toast.success('Reference added successfully');
    return { ...state, references: [...state.references, action.payload] };
  }

  [ReferencesAction.REQUEST_UPDATE_REFERENCE_FINISHED](state, action) {
    toast.success('Reference updated successfully');
    return {
      ...state,
      references: state.references.map((w) => (w.id === action.payload.id ? action.payload : w))
    };
  }

  [ReferencesAction.REQUEST_DELETE_REFERENCE_FINISHED](state, action) {
    toast.success('Reference deleted successfully');
    return {
      ...state,
      references: state.references.filter(({ id }) => id !== action.payload.id)
    };
  }
}
