import BaseReducer from '../../utilities/base-reducer';
import CandidatesAction from './candidates-action';

export default class CandidatesReducer extends BaseReducer {
  initialState = {
    candidates: []
  };

  [CandidatesAction.REQUEST_CANDIDATES_FINISHED](state, action) {
    return { ...state, candidates: action.payload };
  }

  [CandidatesAction.REQUEST_UPDATE_CANDIDATE_FINISHED](state, action) {
    return {
      ...state,
      candidates: state.candidates.map((c) => (c.id === action.payload.id ? action.payload : c))
    };
  }
}
