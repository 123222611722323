import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import RequestingReducer from './requesting/requesting-reducer';
import ErrorReducer from './error/error-reducer';
import AuthReducer from './auth/auth-reducer';
import FiltersReducer from './filters/filters-reducer';
import ModalReducer from './modal/modal-reducer';
import JobsReducer from './jobs/jobs-reducer';
import SidebarReducer from './sidebar/sidebar-reducer';
import CandidatesReducer from './candidates/candidates-reducer';
import ProvidersReducer from './providers/providers-reducer';
import WorkHistoriesReducer from './work-histories/work-histories-reducer';
import EducationsReducer from './educations/educations-reducer';
import ReferencesReducer from './references/references-reducer';
import MenuReducer from './menu/menu-reducer';

export default (history) => {
  const reducerMap = {
    router: connectRouter(history),
    requesting: RequestingReducer.reducer,
    error: ErrorReducer.reducer,
    auth: new AuthReducer().reducer,
    filters: new FiltersReducer().reducer,
    modal: new ModalReducer().reducer,
    jobs: new JobsReducer().reducer,
    sidebar: new SidebarReducer().reducer,
    candidates: new CandidatesReducer().reducer,
    providers: new ProvidersReducer().reducer,
    workHistories: new WorkHistoriesReducer().reducer,
    educations: new EducationsReducer().reducer,
    references: new ReferencesReducer().reducer,
    menu: new MenuReducer().reducer
  };

  return combineReducers(reducerMap);
};
