import ApiRoutes from '../../shared/api-routes/api-routes';
import EffectUtility from '../../utilities/effect-utility';
import CandidateResponseModel from './models/candidate-response-model';

export default class CandidatesEffect {
  static async requestCandidates() {
    return EffectUtility.getToModel(CandidateResponseModel, `${ApiRoutes.candidate}`);
  }

  static async requestUpdateCandidate(data) {
    return EffectUtility.postToModel(CandidateResponseModel, `${ApiRoutes.candidate}/edit`, data);
  }
}
