import { toast } from 'react-toastify';
import BaseReducer from '../../utilities/base-reducer';
import EducationsAction from './educations-action';

export default class EducationsReducer extends BaseReducer {
  initialState = {
    educations: []
  };

  [EducationsAction.REQUEST_EDUCATIONS_FINISHED](state, action) {
    return { ...state, educations: action.payload };
  }

  [EducationsAction.REQUEST_CREATE_EDUCATION_FINISHED](state, action) {
    toast.success('Education created successfully');
    return { ...state, educations: [...state.educations, action.payload] };
  }

  [EducationsAction.REQUEST_UPDATE_EDUCATION_FINISHED](state, action) {
    toast.success('Education updated successfully');
    return {
      ...state,
      educations: state.educations.map((w) => (w.id === action.payload.id ? action.payload : w))
    };
  }

  [EducationsAction.REQUEST_DELETE_EDUCATION_FINISHED](state, action) {
    toast.success('Education deleted successfully');
    return {
      ...state,
      educations: state.educations.filter(({ id }) => id !== action.payload.id)
    };
  }
}
