import AuthEffect from './auth-effect';
import ActionUtility from '../../utilities/action-utility';

export default class AuthAction {
  static UPDATE_REGISTER_DATA = 'AuthAction.UPDATE_REGISTER_DATA';
  static updateRegisterData(data) {
    return ActionUtility.createAction(AuthAction.UPDATE_REGISTER_DATA, data);
  }

  static SIGNIN_FROM_OAUTH = 'AuthAction.SIGNIN_FROM_OAUTH';
  static signinFromOAuth(data) {
    return ActionUtility.createAction(AuthAction.SIGNIN_FROM_OAUTH, data);
  }

  static REQUEST_SIGNIN = 'AuthAction.REQUEST_SIGNIN';
  static REQUEST_SIGNIN_FINISHED = 'AuthAction.REQUEST_SIGNIN_FINISHED';
  static requestSignin(email, password) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AuthAction.REQUEST_SIGNIN,
        AuthEffect.requestSignin,
        email,
        password
      );
    };
  }

  static REQUEST_SEEKER_SIGNUP = 'AuthAction.REQUEST_SEEKER_SIGNUP';
  static REQUEST_SEEKER_SIGNUP_FINISHED = 'AuthAction.REQUEST_SEEKER_SIGNUP_FINISHED';
  static requestSeekerSignup(requestData) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AuthAction.REQUEST_SEEKER_SIGNUP,
        AuthEffect.requestSeekerSignup,
        requestData
      );
    };
  }

  static REQUEST_PROVIDER_SIGNUP = 'AuthAction.REQUEST_PROVIDER_SIGNUP';
  static REQUEST_PROVIDER_SIGNUP_FINISHED = 'AuthAction.REQUEST_PROVIDER_SIGNUP_FINISHED';
  static requestProviderSignup(requestData) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AuthAction.REQUEST_PROVIDER_SIGNUP,
        AuthEffect.requestProviderSignup,
        requestData
      );
    };
  }

  static REQUEST_ACTIVATE_USER = 'AuthAction.REQUEST_ACTIVATE_USER';
  static REQUEST_ACTIVATE_USER_FINISHED = 'AuthAction.REQUEST_ACTIVATE_USER_FINISHED';
  static requestActivateUser(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AuthAction.REQUEST_ACTIVATE_USER,
        AuthEffect.requestActivateUser,
        data
      );
    };
  }

  static REQUEST_RESET_PASSWORD = 'AuthAction.REQUEST_RESET_PASSWORD';
  static REQUEST_RESET_PASSWORD_FINISHED = 'AuthAction.REQUEST_RESET_PASSWORD_FINISHED';
  static requestResetPassword(data) {
    return async (dispatch) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AuthAction.REQUEST_RESET_PASSWORD,
        AuthEffect.requestResetPassword,
        data
      );
    };
  }

  static REQUEST_CHANGE_PASSWORD = 'AuthAction.REQUEST_CHANGE_PASSWORD';
  static REQUEST_CHANGE_PASSWORD_FINISHED = 'AuthAction.REQUEST_CHANGE_PASSWORD_FINISHED';
  static requestChangePassword(data) {
    return async (dispatch) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AuthAction.REQUEST_CHANGE_PASSWORD,
        AuthEffect.requestChangePassword,
        data
      );
    };
  }

  static UPDATE_SIGNUP_STATUS = 'AuthAction.UPDATE_SIGNUP_STATUS';
  static updateSignupStatus(data) {
    return ActionUtility.createAction(AuthAction.UPDATE_SIGNUP_STATUS, data);
  }

  static REQUEST_USER = 'AuthAction.REQUEST_USER';
  static REQUEST_USER_FINISHED = 'AuthAction.REQUEST_USER_FINISHED';
  static requestUser() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AuthAction.REQUEST_USER,
        AuthEffect.requestUser
      );
    };
  }

  static REQUEST_CONFIG = 'AuthAction.REQUEST_CONFIG';
  static REQUEST_CONFIG_FINISHED = 'AuthAction.REQUEST_CONFIG_FINISHED';
  static requestConfig() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AuthAction.REQUEST_CONFIG,
        AuthEffect.requestConfig
      );
    };
  }

  static REQUEST_UPDATE_SEEKER = 'AuthAction.REQUEST_UPDATE_SEEKER';
  static REQUEST_UPDATE_SEEKER_FINISHED = 'AuthAction.REQUEST_UPDATE_SEEKER_FINISHED';
  static requestUpdateSeeker(data) {
    return async (dispatch) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AuthAction.REQUEST_UPDATE_SEEKER,
        AuthEffect.requestUpdateSeeker,
        data
      );
    };
  }

  static REQUEST_UPDATE_PROVIDER = 'AuthAction.REQUEST_UPDATE_PROVIDER';
  static REQUEST_UPDATE_PROVIDER_FINISHED = 'AuthAction.REQUEST_UPDATE_PROVIDER_FINISHED';
  static requestUpdateProvider(data) {
    return async (dispatch) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AuthAction.REQUEST_UPDATE_PROVIDER,
        AuthEffect.requestUpdateProvider,
        data
      );
    };
  }

  static REQUEST_UPDATE_AVATAR = 'AuthAction.REQUEST_UPDATE_AVATAR';
  static REQUEST_UPDATE_AVATAR_FINISHED = 'AuthAction.REQUEST_UPDATE_AVATAR_FINISHED';
  static requestUpdateAvatar(file) {
    return async (dispatch) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AuthAction.REQUEST_UPDATE_AVATAR,
        AuthEffect.requestUpdateAvatar,
        file
      );
    };
  }

  static LOGOUT_USER = 'AuthAction.LOGOUT_USER';
  static requestLogout() {
    return ActionUtility.createAction(AuthAction.LOGOUT_USER);
  }
}
