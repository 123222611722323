import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store/store';
import App from './App';
import { history } from './store/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@duik/it/dist/styles.css';
import '@duik/icon/dist/styles.css';
import 'sweetalert2/src/sweetalert2.scss';
import './assets/scss/index.scss';

(async (window) => {
  const initialState = {};
  const store = configureStore(initialState);

  const rootEl = document.getElementById('root');
  const render = (Component, el) => {
    ReactDOM.render(
      <Provider store={store}>
        <Component history={history} dispatch={store.dispatch} />
      </Provider>,
      el
    );
  };

  render(App, rootEl);
})(window);
